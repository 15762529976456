<div #appCancelOfferModal class="modal fade accept-offer">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <div>
            <app-slide class="nft-container" [slideCount]="2" [slideIndex]="slideIndex" >
              <div first-slide class="qr-code">
                <span *ngIf="xummInteractionQrCode">
                  Scan this code in the XUMM app to complete your transaction
                </span>
  
                <app-scan-qr-code *ngIf="isInteracting && !waitingMessage" class="xumm-interaction-qr-code"
                  [signInQrUrl]="xummInteractionQrUrl"
                  [signInQrCode]="xummInteractionQrCode"
                  (cancel)="cancelXummInteraction()"
                ></app-scan-qr-code>
              </div>
  
              <div second-slide>
                <app-waiting [message]="waitingMessage" [isWaiting]="!!waitingMessage" [fullScreen]="false"></app-waiting>
              </div>
  
            </app-slide>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-no-xumm-modal></app-no-xumm-modal>