import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-nft-card',
  templateUrl: './nft-card.component.html',
  styleUrls: ['./nft-card.component.scss'],
})
export class NftCardComponent {
  @Input()
  nftTokenId?: string;

  @Input()
  name?: string;

  @Input()
  image: string | undefined;

  @Input()
  video: string | undefined;

  @Input()
  isFavorite?: boolean;

  constructor() {}
}
