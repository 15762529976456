<div class="listing">
  <div
    #container
    class="listing__container glass"
    (click)="gotoNftDetail($event)"
  >
    <div class="listing__content">
      <div class="listing__body">
        <div class="listing__info">
          <div class="nft-container">
            <div class="image-content">
              <img [src]="media" alt="" *ngIf="!hasVideo" />

              <video *ngIf="hasVideo" [controls]="true" [autoplay]="false" loop>
                <source [src]="media" />
              </video>
            </div>
          </div>
        </div>
        <div class="listing__main">
          <div class="listing__detail">
            <div class="listing__display">
              <span>{{ name }}</span>
              <span>Ranking: {{ ranking }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
