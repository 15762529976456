import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IAssetCollection } from 'src/app/shared/models/assets/asset-collection';

@Component({
  selector: 'app-asset-collection-card',
  templateUrl: './asset-collection-card.component.html',
  styleUrls: ['./asset-collection-card.component.scss'],
})
export class AssetCollectionCardComponent {

  @Input()
  assetCollection?: IAssetCollection;

  @Input()
  isFavorite?: boolean;

  @Input()
  isOwner: boolean = false;

  constructor(private router: Router) {}

  gotoCollection () {
    this.router.navigate([`/collections/${this.assetCollection?.assetCollectionGuid}`]);
  }

  editCollection(event: any) {
    this.router.navigate([`/collections/${this.assetCollection?.assetCollectionGuid}/edit`]);
    event.stopPropagation();
  }
  createAssetSeries(event: any) {
    this.router.navigate([`/collections/${this.assetCollection?.assetCollectionGuid}/series/create`]);
    event.stopPropagation();
  }

}
