<div #modal class="modal fade make-offer" [ngClass]="{ 'make-offer--show': showMakeOffer }">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <div>
          <app-slide [slideIndex]="slideIndex" [slideCount]="3">

            <div first-slide class="signin-qr">
              <span *ngIf="xummQrCode">
                Scan this code in the XUMM app to complete your offer
              </span>

              <app-scan-qr-code [signInQrUrl]="xummQrUrl" [signInQrCode]="xummQrCode"
                (cancel)="cancel()"></app-scan-qr-code>

            </div>

            <div second-slide>
              <div>
                <h2 class="head head--medium">
                  Make NFT offer
                </h2>
                {{currencyText}}
              </div>

              <form [formGroup]="offerGroup">

                <div class="options">
                  <div class="option">
                    <input type="radio" id="currency_xrp" [value]="xrpCurrencyGuid" (change)="selectOption($event)"
                      formControlName="currency">
                    <label for="currency_xrp">xrp</label>
                  </div>
                  <div class="option">
                    <input type="radio" id="currency_xspec" [value]="xSPECTARCurrencyGuid"
                      (change)="selectOption($event)" formControlName="currency">
                    <label for="currency_xspec">xSPECTAR</label>
                  </div>
                </div>

                <p class="amount-title">Select amount</p>
                <div class="amount">
                  <input type="number" min="1" step="1" name="amountToBuyWith" formControlName="amountToBuyWith">
                  <label for="amount">{{selectedCurrencyName}}</label>
                </div>

                <div class="submit">
                  <button class="button button--tertairy-alt" (click)="makeNewOffer()">Submit</button>
                </div>

              </form>

              <a href="#" class="cancel" (click)="cancel()">Cancel</a>

            </div>

            <div third-slide>
              <app-waiting [isWaiting]="!!waitingMessage" [fullScreen]="false" [message]="waitingMessage"></app-waiting>
            </div>

          </app-slide>
        </div>
      </div>
    </div>
  </div>