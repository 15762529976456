<div class="listing">
  <div
    #container
    class="listing__container glass"
  >
    <div class="listing__content">
      <div class="listing__body">
        <div class="listing__info">
          <div class="nft-container">
            <div class="image-content">

              <img [src]="displayMedia" alt="" *ngIf="displayMediaType=='image'" />

              <video *ngIf="displayMediaType=='video'" [controls]="true" [autoplay]="false" loop>
                <source [src]="displayMedia" />
              </video>

              <audio controls *ngIf="displayMediaType=='audio'">
                <source [src]="displayMedia">
              </audio>

            </div>
          </div>
        </div>
        <div class="listing__main">
          <div class="listing__detail">
            <div class="listing__display">
              <span>{{ _asset?.assetName }}</span>
              <span>Ranking: {{ ranking }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
