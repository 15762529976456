<div #noXummModal class="modal fade no-wallet">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
        <div>
          <p>You do not currently have a wallet linked to your account</p>

          <a class="button" (click)="noModal.hide()" routerLink="/account/link-accounts">Go to account</a>
          <button class="button button--tertairy-alt" (click)="noModal.hide()">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>