<form [formGroup]="registerGroup" (submit)="register()">
  <div class="text-center">
    <h2 class="head head--small">
      Sign up
    </h2>
    <p>
      Create your xspectar account
    </p>
  </div>
  <div>
    <label>
      <span class="visually-hidden">Email</span>
      <input type="email" name="email" formControlName="email" placeholder="email"/>
      <div *ngIf="formSubmitted && registerGroup.get('email')?.invalid" class="form-error">
        <div *ngIf="registerGroup.get('email')?.errors?.['required']">
          Please enter an email.
        </div>
      </div>
      <div *ngIf="formSubmitted && registerGroup.get('email')?.invalid" class="form-error">
        <div *ngIf="registerGroup.get('email')?.errors?.['email']">
          Please enter a valid email.
        </div>
      </div>
    </label>
  </div>
  <div>
    <label>
      <span class="visually-hidden">Password</span>
      <input type="password" name="password" formControlName="password" placeholder="password" />
      <div *ngIf="formSubmitted && registerGroup.get('password')?.invalid" class="form-error">
        <div *ngIf="registerGroup.get('password')?.errors?.['required']">
          Please enter a password.
        </div>
      </div>
      <div *ngIf="formSubmitted && registerGroup.get('password')?.invalid" class="form-error">
        <div *ngIf="registerGroup.get('password')?.errors?.['pattern']">
          Password must be at least 6 characters and contain 1 lowercase, uppercase, digit and special character.
        </div>
      </div>
    </label>
  </div>

  <div>
    <div class="form-input-group form-input-group--checkbox">
      <input type="checkbox" role="switch" formControlName="terms" name="terms" id="terms">
      <small>I agree to xSPECTAR's <a href="{{urlService.createMarketingSiteUrl('terms-and-conditions.html')}}" target="_blank">Terms and Conditions</a> and that my personal data will be processed pursuant to the <a href="{{urlService.createMarketingSiteUrl('privacy-policy.html')}}" target="_blank">Privacy Policy</a>.</small>
    </div>
    <div *ngIf="formSubmitted && registerGroup.get('terms')?.invalid" class="form-error">
      <div *ngIf="registerGroup.get('terms')?.errors?.['required']">
        Please agree to xSPECTAR's Terms and Conditions.
      </div>
    </div>
  </div>

  <div *ngIf="formSubmitted && formError && !formErrorMessage" class="form-error">
    <div>
      Something went wrong
    </div>
  </div>

  <div *ngIf="formSubmitted && formError && formErrorMessage" class="form-error">
    <div>
      {{ formErrorMessage }}
    </div>
  </div>

  <button type="submit" class="button button--primary" [ngClass]="{'button--disabled': !registerGroup.valid}">Sign Up</button>
</form>
