import { Component, OnInit, Input, ViewChild} from '@angular/core';
import { SecurityService } from 'src/app/shared/services/security.service';
import { AccountService } from 'src/app/shared/services/account.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { NotificationType } from 'src/app/shared/services/notifications/notification-type';
import { MarketplaceService } from 'src/app/shared/services/marketplace.service';
import { IAssetSeries } from 'src/app/shared/models/assets/asset-series';
import { IFindPagedAssetsInSeriesResponse } from 'src/app/shared/models/assets/FindPagedAssetsInSeriesResponse';
import { IAccountDetails } from 'src/app/shared/models/security';
import { IAssetAttributeType } from 'src/app/shared/models/assets/asset-attribute-type';
import { firstValueFrom } from 'rxjs';
import { ServiceResultStatus } from 'src/app/shared/services/results/service-result-status';
import { ActivatedRoute, Router } from '@angular/router';
import { IAsset } from 'src/app/shared/models/assets/asset';
import { IFindPagedAssetsInSeriesRequest } from 'src/app/shared/models/assets/IFindPagedAssetsInSeriesRequest';
import { AssetSeriesAssetFilterSettings, filterAttributeType } from 'src/app/shared/models/assets/AssetSeriesAssetFilterSettings';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-asset-series-assets',
  templateUrl: './asset-series-assets.component.html',
  styleUrls: ['./asset-series-assets.component.scss']
})
export class AssetSeriesAssetsComponent implements OnInit {

  throttle = 300;
  scrollDistance = 1;

  @Input()
  assetSeries?: IAssetSeries;

  @Input()
  account?: IAccountDetails;

  isFilterVisible: boolean = true;

  isDesktop: boolean = true;
  take: number = 30;
  nextPage: number = 0;

  assetAttributeTypes: IAssetAttributeType[] = [];
  assetsSearchResult?: IFindPagedAssetsInSeriesResponse;
  visibleAssets: IAsset[] = [];
  totalPossible: number = -1;

  filterSettings?: AssetSeriesAssetFilterSettings;

  initialLoadDone: boolean = false;
  loadingResults: boolean = false;

  get xrplCurrencyGuid(): string {
    return environment.xrpCurrencyGuid;
  }
  get xspectarlCurrencyGuid(): string {
    return environment.xSpectarCurrencyGuid;
  }

  constructor(private securityService: SecurityService,
    private accountService: AccountService,
    private marketplaceService: MarketplaceService,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private router: Router,) {

      notificationService.listenFor(NotificationType.SignOut).subscribe(() => {
        
      });
      notificationService.listenFor(NotificationType.SignInConfirmed).subscribe(() => {
        
      });

  }

  async ngOnInit(): Promise<void> {

    window.onresize = () => {
      this.isDesktop = !(window.innerWidth < 800);
      if (this.filterSettings) {
        this.filterSettings.isDesktop = this.isDesktop;
      }
    };

    
    await this.getAssetSeriesAttributes();
    this.filterSettings = this.getDefaultSearch();
    await this.findPagedAssetsForSeries();

    this.initialLoadDone = true;

  }

  
  async getAssetSeriesAttributes() {
    
    let response = await firstValueFrom(this.marketplaceService.getAssetsAttributes(this.assetSeries!.assetSeriesGuid));
    
    if (response.status != ServiceResultStatus.Success) {
      this.router.navigate([`/`]);
        return;
    }

    this.assetAttributeTypes = response.data!;

  }

  async findPagedAssetsForSeries() {

    if (this.totalPossible > -1 && this.visibleAssets.length >= this.totalPossible) {
      return;
    }

    this.loadingResults = true;

    let search : IFindPagedAssetsInSeriesRequest = {
      take: this.take,
      from: this.take * this.nextPage,
      seriesGuid: this.assetSeries!.assetSeriesGuid
    };

    let response = await firstValueFrom(this.marketplaceService.findPagedAssetsForSeries(search));
    
    if (response.status != ServiceResultStatus.Success || !response.data) {
        return;
    }

    this.assetsSearchResult = response.data;
    this.totalPossible = response.data.totalAssets;
    this.visibleAssets.push(...response.data.assets);
    this.nextPage++;
    this.loadingResults = false;

  }

  async resetFilter() {
    this.filterSettings = this.getDefaultSearch();
    this.nextPage = 1;
    await this.findPagedAssetsForSeries();
  }

  getDefaultSearch() : AssetSeriesAssetFilterSettings {

    let types = {} as filterAttributeType;
    
    if (this.assetAttributeTypes){
      this.assetAttributeTypes!.forEach(e => {
        types[e.attributeTypeGuid] = {
          show: true,
          attributeTypeName: e.attributeTypeName,
          attributes: e.assetAttributes
        }
      })
    }

    return {
      selectedFilters : [],
      
      searchText : "",
      attributeTypes: types,

      isDesktop: this.isDesktop,
      
      sortByAsc: true,
      sortBy: 'name',

      hasBuyOffers: undefined,
      hasSellOffers: undefined,

      showMyNfts: false,
      combineAttributesWith: "and",
      
      canTransferToMe: false,

      filterByCurrencyGuid: undefined,

      isLoggedIn: false,

    };

  }

  setFilter(filterSettings: AssetSeriesAssetFilterSettings) {
    this.filterSettings = filterSettings;
  }

  sortBy(type: string, currencyGuid: string | undefined = undefined) {

    if (!this.filterSettings) {
      return;
    }

    this.filterSettings.sortBy = type;
    this.filterSettings.filterByCurrencyGuid = currencyGuid;

  }
  sortAsc(asc: boolean) {
    
    if (!this.filterSettings) {
      return;
    }

    this.filterSettings.sortByAsc = asc;
  }

  showFilterModal(show: boolean) {
    this.isFilterVisible = show;
  }

  public onScrollDown() {
    if (this.visibleAssets && this.visibleAssets.length < this.totalPossible && !this.loadingResults) {
      this.findPagedAssetsForSeries();
    }
  }

}
