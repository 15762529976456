<div class="alert__container">
  <div *ngFor="let alert of alerts">
    <alert
      [type]="alert.type"
      [dismissible]="alert.dismissible"
      (onClosed)="onClosed(alert)"
      >{{ alert.message }}</alert
    >
  </div>
</div>
