import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NftCreateOfferProgressStatus } from 'src/app/shared/models/marketplace';
import { IXls20NftDetails } from 'src/app/shared/models/xls-20-nfts';
import { AccountService } from 'src/app/shared/services/account.service';
import { MarketplaceService } from 'src/app/shared/services/marketplace.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SecurityService } from 'src/app/shared/services/security.service';
import { environment } from 'src/environments/environment.beta';

@Component({
  selector: 'app-xls-20-make-offer',
  templateUrl: './xls-20-make-offer.component.html',
  styleUrls: ['./xls-20-make-offer.component.scss']
})
export class Xls20MakeOfferComponent implements AfterViewInit {
  @ViewChild('modal') modalRef?: ElementRef;

  nft: IXls20NftDetails | undefined;
  xummQrCode: string | undefined;
  xummQrUrl: SafeResourceUrl | undefined;

  showMakeOffer: boolean = false;
  waitingMessage: string | undefined;
  slideIndex: number = 1;
  isInteracting: boolean = false;

  selectedCurrency: string;
  selectedCurrencyName: string = "";

  xrpCurrencyGuid: string = "";
  xSPECTARCurrencyGuid : string;

  offerGroup: FormGroup;
  currencyText: string = '';
  offerModal: any;

  @Input()
  set xls20Nft(value: IXls20NftDetails | undefined) {
    this.nft = value;
    this.showMakeOffer = this.nft != undefined;
    this.currencyText = this.nft?.isOwnedByUser ?
      'Select the currency and amount you wish to sell for' :
      'Select the currency and amount you wish to pay';
    this.slideIndex = 1;

    if (value !== undefined) {
      this.offerModal.show();
    }
  }

  @Output()
  xummInteractionEnd: EventEmitter<IXls20NftDetails> = new EventEmitter<IXls20NftDetails>();
  @Output()
  xummInteractionStart: EventEmitter<IXls20NftDetails> = new EventEmitter<IXls20NftDetails>();


  constructor(
    private notificationService: NotificationService,
    private securityService: SecurityService,
    private accountService: AccountService,
    private marketplaceService: MarketplaceService,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private window: Window
    ) {

      this.xrpCurrencyGuid = environment.xrpCurrencyGuid;
      this.xSPECTARCurrencyGuid = environment.xSpectarCurrencyGuid;

      this.selectedCurrency = this.xrpCurrencyGuid;
      this.selectedCurrencyName = "xrp";

      this.offerGroup = fb.group({
        amountToBuyWith: ['0'],
        currency: [this.xrpCurrencyGuid]
      });
    }

  ngAfterViewInit() {
    this.offerModal = new this.window.bootstrap.Modal(this.modalRef?.nativeElement, {
      backdrop: 'static',
      keyboard: false
    });
  }

  cancel() {
    this.clearOffer();
    this.xummInteractionEnd.emit(this.nft);
    return false;
  }
  clearOffer() {
    this.xummQrCode = undefined;
    this.xummQrUrl = undefined;
    this.waitingMessage = undefined;
    this.nft = undefined;
    this.showMakeOffer = false;
    this.xls20Nft = undefined;
    this.offerModal.hide();
  }
  selectOption(event : any) {
    this.selectedCurrency = this.offerGroup.get('currency')?.value;

    if (this.selectedCurrency == this.xrpCurrencyGuid) {
      this.selectedCurrencyName = "xrp";
    } else {
      this.selectedCurrencyName = "xSPECTAR";
    }

  }
  makeNewOffer() {

    this.xummInteractionStart.emit(this.nft);
    this.waitingMessage = 'CREATING QR CODE';
    this.slideIndex = 2;
    this.isInteracting = true;

    this.securityService.getAccountDetails().subscribe(accountDetailsResponse => {

      if (accountDetailsResponse.data == null) {
        this.cancel();
        console.log("you need to be logged in to use the marketplace");
        return;
      }

      let account = accountDetailsResponse.data;

      this.accountService.getAccountPaymentInstruments().subscribe(paymentInstruments => {

        let paymentInstrument = paymentInstruments.data?.filter(e => e.PaymentInstrumentBlockchainProviderGuid = environment.xrplBlockchainProviderGuid);

        if (!paymentInstrument || paymentInstrument?.length == 0) {
          this.cancel();
          console.log("Could not find wallet in your account for the XRPL network");
          return;
        }

        let nftName = this.nft!.name ?? this.nft!.nftokenId.substring(this.nft!.nftokenId.length - 10);

        // change to make once done
        this.marketplaceService.makeXls20NftOffer(
          this.nft!.ownerWalletDetails!.address,
          this.offerGroup.get('amountToBuyWith')?.value,
          this.nft!.nftokenId,
          nftName,
          account.accountGuid ?? "",
          paymentInstrument[0].paymentInstrumentGuid,
          this.offerGroup.get('currency')?.value,
          this.nft!.isOwnedByUser
        ).subscribe(notificationResult => {

          if (notificationResult.immediateResponse) {
            this.xummQrCode = notificationResult.immediateResponse.data.qrCodeImage;
            this.xummQrUrl = this.sanitizer.bypassSecurityTrustResourceUrl(notificationResult.immediateResponse.data.qrCodeUrl);
            this.waitingMessage = undefined;
            this.slideIndex = 0;

            let currentProgress = 0;
            notificationResult.subscribe(result => {
              this.waitingMessage = 'Please Wait';

              switch(result.progressStatus) {
                case NftCreateOfferProgressStatus.Complete:

                  if (currentProgress <= 1) {
                    this.waitingMessage = 'NFT OFFER CREATED!';
                    this.slideIndex = 2;

                    setTimeout(() => {
                      this.cancel();
                    }, 2000)

                    currentProgress = 2;
                  }
                  break;
                case NftCreateOfferProgressStatus.Failed:
                  this.waitingMessage = `NFT OFFER FAILED`;
                  this.slideIndex = 2;

                  setTimeout(() => {
                    this.cancel();
                  }, 2000)

                  break;
              }
            });

          }

        });

      })

    })
  }


}
