import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss'],
})
export class PageLayoutComponent {
  containerClasses: string[] = [];
  routes: { regex: RegExp; classes?: string[] }[] = [
    {
      regex: /^\/profile\/[0-9]+\/[a-zA-z]+/,
      classes: ['profile-section'],
    },
  ];
  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.containerClasses = [];
        this.setupContainerClasses();
      }
    });
  }

  setupContainerClasses() {
    const currentRoute = this.router.url;
    const route = this.routes.find((route) => route.regex.test(currentRoute));
    if (route?.classes) {
      this.containerClasses = route.classes;
    }
  }
}
