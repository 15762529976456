
<div class="collection">
  <div>
    <div class="collection__filter-toggle" [ngClass]="{'collection__filter-toggle--hide': !isDesktop}">
      <button type="button" (click)="isFilterVisible = !isFilterVisible">
        {{ isFilterVisible ? 'Hide filter': 'Show filter' }}
        <i class="fa-solid fa-sliders"></i>
      </button>

    </div>
    <div class="collection__filter-toggle" [ngClass]="{'collection__filter-toggle--hide': !isDesktop}">
      <button class="collection__filter-toggle" (click)="resetFilter()">
        Reset filter
      </button>
    </div>

    <!-- <div class="search">
      <label>
        <span class="visually-hidden">Search</span>
        <input type="text" placeholder="Search by name, rank or trait" />
      </label>

      <label>
        Sorted by

        <select>
          <option>Name</option>
        </select>
      </label>

      <div>
        <button type="button" title="grid" (click)="currentDisplayType = displayType.Grid"><i class=""></i></button>
        <button type="button" title="list" (click)="currentDisplayType = displayType.List"><i class="fa-solid fa-list"></i></button>
      </div>
    </div> -->
  </div>
  <div> 
    <div [ngClass]="{'collection__hide-filter': !isDesktop && isFilterVisible}">
      <app-asset-series-asset-filter
        [filterSettings]="filterSettings!"
        [attributeList]="assetAttributeTypes"
        (filter)="setFilter($event)">
      </app-asset-series-asset-filter>
    </div>

    <div>
      <div class="collection__nft-count">
        {{totalPossible}} Results
      </div>
      <div class="collection__sort-by" *ngIf="filterSettings">
        <div class="collection__sort-by-type">
          <span class="title">Sort by: </span>
          <span (click)="sortBy('name')" [ngClass]="{ selected : filterSettings.sortBy == 'name' }">Name</span>
          <span (click)="sortBy('ranking')" [ngClass]="{ selected : filterSettings.sortBy == 'ranking' }">Ranking</span>
          <span (click)="sortBy('price', filterSettings!.filterByCurrencyGuid || xrplCurrencyGuid)" [ngClass]="{ selected : filterSettings.sortBy == 'price', disabled : !filterSettings.filterByCurrencyGuid}">Price</span>
        </div>
        <div class="collection__sort-by-currency">
          <span class="title">Currency: </span>
          <span (click)="sortBy('price', xrplCurrencyGuid)" [ngClass]="{ selected : filterSettings.sortBy == 'price' && filterSettings.filterByCurrencyGuid == xrplCurrencyGuid}">XRP</span>
          <span (click)="sortBy('price', 'xspectar')" [ngClass]="{ selected : filterSettings.sortBy == 'price' && filterSettings.filterByCurrencyGuid == xspectarlCurrencyGuid }">xSpect</span>
        </div>
        <div class="collection__sort-by-asc">
          <span class="title">Direction: </span>
          <span (click)="sortAsc(true)"  [ngClass]="{ selected : filterSettings.sortByAsc }">a-z | 0-9</span>
          <span (click)="sortAsc(false)"  [ngClass]="{ selected : !filterSettings.sortByAsc }">z-a | 9-0</span>
        </div>
      </div>

      <div class="collection__filter-toggle collection__filter-toggle--mobile" *ngIf="isDesktop">
        <button type="button" (click)="showFilterModal(true)">
          Filter
          <i class="fa-solid fa-sliders"></i>
        </button>
      </div>

      <div class="collection__no-nfts" *ngIf="totalPossible <= 0 && initialLoadDone">

        <span>No NFTs match your search and filter combination</span>
        <button class="button" type="button" (click)="resetFilter()">
          Clear search & filter
        </button>

      </div>

      <section
        class="collection__results row"
        infinite-scroll
        [infiniteScrollDistance]="scrollDistance"
        [infiniteScrollThrottle]="throttle"
        (scrolled)="onScrollDown()"
      >
        <app-asset-listing
          [asset]="asset"
          *ngFor="let asset of visibleAssets!"
        ></app-asset-listing>
      </section>

      <div class="collection__result-loading" *ngIf="loadingResults">
        <app-waiting [isWaiting]="true" [fullScreen]="false"></app-waiting>
      </div>

    </div>

  </div>
</div>
