import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, map, Observable, Subject } from 'rxjs';
import { IBlockchainNftAttributeCollectionSummary, INftAttributeFilterType } from 'src/app/shared/models/xls-20-nfts/blockchain-nft-attribute-collection-summary';

interface attributes {
  [x : string]: {
    show: boolean,
    values: INftAttributeFilterType[]
  },
}

@Component({
  selector: 'app-xls-20-filter',
  templateUrl: './xls-20-filter.component.html',
  styleUrls: ['./xls-20-filter.component.scss']
})
export class Xls20FilterComponent implements OnInit {
  subject = new Subject<string>();

  _attributeList: attributes = {};
  tempFilters: NftFilterItem[] = [];
  tempShowOnlyMine: boolean = false;
  tempCanTransferToMe: boolean = false;
  tempShowTresuryMine: boolean = false;
  tempSearchText: string = "";

  tempWithBuyOffers: boolean | undefined = false;
  tempWithSellOffers: boolean | undefined = false;
  tempCombineAttributesWith: string = "and";

  showModal: boolean = false;
  _filterSettings : FilterSettings;
  results$!: Observable<void>;
  currentCollection: string | undefined;

  suppressOnCheckboxChanged: boolean = false;
  isFilterVisible: boolean = true;

  @Input() set
  filterSettings(value: FilterSettings) {

    this.tempFilters = [];
    this._filterSettings = value;

    this.tempShowOnlyMine = false;
    this.tempCanTransferToMe = false;
    this.tempShowTresuryMine = false;
    this.tempSearchText = "";
    this.tempWithBuyOffers = false;
    this.tempWithSellOffers = false;
    this.tempShowTresuryMine = false;
    this.tempCombineAttributesWith = "and";

    this.setupFilters();
  }

  @Input() set
  attributeList(value : IBlockchainNftAttributeCollectionSummary[] | undefined) {

    this._attributeList = {};
    value?.forEach(element => {

      this._attributeList[element.name] = {
        show: false,
        values: []
      }
      this._attributeList[element.name].values = element.attributes;
      this._attributeList[element.name].values.sort((a,b) => {

        if (a.value > b.value) return 1;
        if (a.value < b.value) return -1;

        return 0;

      });

    });

    this.setupFilters();

  }

  @Input() set isVisible(value: boolean) {
    this.isFilterVisible = value;
  }

  @Input() showTraits: boolean = true;

  @Output()
  search: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  filter: EventEmitter<NftFilterItem[]> = new EventEmitter<NftFilterItem[]>();
  @Output()
  showMyNftsOnly: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  canTransferToMe: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  mobileSearch: EventEmitter<FilterSettings> = new EventEmitter<FilterSettings>();
  @Output()
  showTresuryNftsOnly: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  showWithBuyOffersNfts: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  showWithSellOffersNfts: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  combineAttributesWith: EventEmitter<string> = new EventEmitter<string>();


  constructor() {

    this._filterSettings = {
      selectedFilters : [],
      showMyNfts: false,
      searchText : "",
      showTresuryNfts : false,
      isDesktop: false,
      isLoggedIn : false,
      sortByAsc: true,
      sortByRanking: true,
      hasBuyOffers: undefined,
      hasSellOffers: undefined,
      combineAttributesWith: "and",
      canTransferToMe: false,
      sortByBids: false,
      currency: "",
    };

  }

  ngOnInit(): void {
    this.subject.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(searchText => this.search.emit(searchText));
  }

  onCheckboxChange(e: any, emit: boolean) {

    if (this.suppressOnCheckboxChanged) {
      return;
    }

    if (this.tempCombineAttributesWith == 'and') {

      let checkbox = (e.currentTarget as HTMLInputElement);
      let isChecked = checkbox.checked;

      Array.from(document.querySelectorAll(`.filter .filter__option input[type=checkbox][name="${checkbox.name}"]`)).forEach(e => {
        let element = (e as HTMLInputElement);
        element.checked = element.value == checkbox.value && isChecked;
      })

    }

    this.tempFilters = Array.from(document.querySelectorAll('.filter .filter__option input[type=checkbox]:checked')).map(_ => {
      let element = (_ as HTMLInputElement);
      return new NftFilterItem(element.name, parseInt(element.value));
    });

    if (emit) {
      this._filterSettings!.selectedFilters = this.tempFilters;
      this.filter.emit(this._filterSettings!.selectedFilters);
    }
  }

  onCanTransferToMe(e: any, emit: boolean) {
    this.tempCanTransferToMe = e.target.checked;

    if (emit) {
      this._filterSettings.canTransferToMe = this.tempCanTransferToMe;
      this.canTransferToMe.emit(this.tempCanTransferToMe);
    }

  }

  onShowMyNfts(e: any, emit: boolean) {
    this.tempShowOnlyMine = e.target.checked;

    if (emit) {
      this._filterSettings.showMyNfts = this.tempShowOnlyMine;
      this.showMyNftsOnly.emit(this.tempShowOnlyMine);
    }

  }
  onShowTresuryNfts(e: any, emit: boolean) {
    this.tempShowTresuryMine = e.target.checked;

    if (emit) {
      this._filterSettings.showTresuryNfts = this.tempShowTresuryMine;
      this.showTresuryNftsOnly.emit(this.tempShowTresuryMine);
    }

  }

  onShowWithBuyOffersNfts(e: any, emit: boolean) {
    this.tempWithBuyOffers = e.target.checked;

    if (!this.tempWithBuyOffers) {
      this.tempWithBuyOffers = undefined;
    }

    if (emit) {
      this._filterSettings.hasBuyOffers = this.tempWithBuyOffers;
      this.showWithBuyOffersNfts.emit(this.tempWithBuyOffers);
    }

  }
  onShowWithSellOffersNfts(e: any, emit: boolean) {

    this.tempWithSellOffers = e.target.checked;
    if (!this.tempWithSellOffers) {
      this.tempWithSellOffers = undefined;
    }

    if (emit) {
      this._filterSettings.hasSellOffers = this.tempWithSellOffers;
      this.showWithSellOffersNfts.emit(this.tempWithSellOffers);
    }

  }
  setCombineAttributesWith(combineWith : string, emit: boolean) {

    this.tempCombineAttributesWith = combineWith;

    if (this.tempCombineAttributesWith == 'and') {

      this.suppressOnCheckboxChanged = true;
      this._filterSettings.selectedFilters.forEach(filter => {

        let foundFirst = false;
        Array.from(document.querySelectorAll(`.filter .filter__option input[type=checkbox][name="${filter.type}"]`)).forEach(e => {
          let element = (e as HTMLInputElement);
          element.checked = element.checked && !foundFirst;
          foundFirst ||= element.checked;
        })

      })

      this.tempFilters = Array.from(document.querySelectorAll('.filter .filter__option input[type=checkbox]:checked')).map(_ => {
        let element = (_ as HTMLInputElement);
        return new NftFilterItem(element.name, parseInt(element.value));
      });

      this.suppressOnCheckboxChanged = false;

    }

    if (emit) {
      this._filterSettings!.selectedFilters = this.tempFilters;
      this._filterSettings.combineAttributesWith = this.tempCombineAttributesWith;
      this.combineAttributesWith.emit(this.tempCombineAttributesWith);
    }

  }

  setSearchText(e: any, emit: boolean) {
    this.tempSearchText = e.target.value;

    if (emit) {
      this.subject.next(e.target.value);
    }
  }

  toggleModal() {
    this.showModal = !this.showModal;

    if (this.showModal) {
      setTimeout(() => {
        document.querySelector('.filter-modal .gold-bordered-block')?.scrollTo({top:0,behavior:'smooth'});
      }, 0);
    }
  }

  applyFilters() {

    this._filterSettings.selectedFilters = this.tempFilters;
    this._filterSettings.searchText = this.tempSearchText;
    this._filterSettings.showMyNfts = this.tempShowOnlyMine;
    this._filterSettings.showTresuryNfts = this.tempShowTresuryMine;
    this._filterSettings.hasBuyOffers = this.tempWithBuyOffers;
    this._filterSettings.hasSellOffers = this.tempWithSellOffers;
    this._filterSettings.combineAttributesWith = this.tempCombineAttributesWith;

    this.mobileSearch.emit(this._filterSettings);
    this.toggleModal()
  }

  isCurrentlySelected(searchId: number) {
    return this.tempFilters.some(_ => _.searchId === searchId);
  }

  cancel() {
    this.tempFilters = this._filterSettings.selectedFilters;

    this.tempSearchText = this._filterSettings.searchText;
    (<HTMLInputElement>document.querySelector('.filter__search > input')).value = this.tempSearchText;

    this.tempShowOnlyMine = this._filterSettings.showMyNfts;
    this.tempShowTresuryMine = this._filterSettings.showTresuryNfts;

    this.toggleModal();
  }

  setupFilters() {
    this.tempFilters.forEach(e => {
      this._attributeList[e.type].show = true;
    })

  }


}

export class NftFilterItem {

  constructor(public type : string, public searchId : number) {}

}
export class FilterSettings {

  selectedFilters: NftFilterItem[] = [];
  showMyNfts: boolean = false;
  searchText : string = "";
  showTresuryNfts: boolean = false;
  combineAttributesWith: string = "and";

  hasSellOffers: boolean | undefined;
  hasBuyOffers: boolean | undefined;
  canTransferToMe: boolean | undefined;

  isDesktop : boolean = false;
  isLoggedIn: boolean = false;
  sortByRanking: boolean = true;
  sortByAsc: boolean = true;
  sortByBids: boolean= false;
  currency: string = "";
}
