import { Component, OnInit, Input, Output, EventEmitter, } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-scan-qr-code',
  templateUrl: './scan-qr-code.component.html',
  styleUrls: ['./scan-qr-code.component.scss']
})
export class ScanQrCodeComponent {

  @Input()
  signInQrCode: string | undefined;

  @Input()
  signInQrUrl: SafeResourceUrl | undefined;

  @Input()
  showMessage: boolean = false;

  @Output()
  cancel = new EventEmitter();

  constructor() {
  }

  emitCancel() : void {
    this.cancel.emit();
  }

}
