import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, of } from "rxjs";
import { MenuServiceMenuItem, MenuServiceMenuItemCollection } from "../models/menu-service-menu-item";
import { SecurityService } from "./security.service";
import { IAccountDetails } from "../models/security";
import { AccountService } from "./account.service";
import { ServiceResultStatus } from "./results/service-result-status";
import { IProfile } from "../models/IProfile";
import { tr } from "date-fns/locale";
import { NotificationService } from "./notification.service";
import { NotificationType } from "./notifications/notification-type";

@Injectable({
  providedIn: 'root',
 })
 export class MenuService {
  

  private loggedIn: boolean = false;;
  private profile: IProfile | undefined;
  private addedMenuItemCollections : MenuServiceMenuItemCollection[] = [];
  private itemsSubject = new Subject<MenuServiceMenuItemCollection[]>();

  constructor(private securityService: SecurityService, private accountService: AccountService, private notificationService: NotificationService) {

    this.accountService.getLoggedInUserProfile()
      .subscribe(result => {
        if (result.status === ServiceResultStatus.Success) {
          this.profile = result.data;
          this.loggedIn = true;
          this.itemsSubject.next(this.returnMenu());
        }
      });

      this.notificationService.listenFor(NotificationType.SignOut).subscribe(() => {
        this.loggedIn = false;
        this.profile = undefined;
        this.itemsSubject.next(this.returnMenu());
      });

  }
  
  getMainMenuItems() : MenuServiceMenuItemCollection {

    let menuItems : MenuServiceMenuItem[] = [];

    if (this.profile) {
      menuItems.push({
        label: "My Profile",
        route: "profile/" + this.profile?.profileGuid
      });
    }

    menuItems.push({label: "Explore", route:"explore"});
    menuItems.push({label: "Statistics", route:"statistics"});

    return {
      label: "Main Menu",
      requiresLogin: false,
      items: menuItems
    };

  }
  returnMenu() {
    return [this.getMainMenuItems(), ...this.addedMenuItemCollections.filter(e => this.loggedIn || !e.requiresLogin)];
  }

  getItems(): Observable<MenuServiceMenuItemCollection[]> {
    return this.itemsSubject;
  }

  addItem(item: MenuServiceMenuItemCollection) {
    this.addedMenuItemCollections.push(item);
    this.itemsSubject.next(this.returnMenu());
  }
  
  clearAll() {
    this.addedMenuItemCollections = [];
    this.itemsSubject.next(this.returnMenu());
  }

  removeCollection(collectionLabel: string) {
    this.addedMenuItemCollections = this.addedMenuItemCollections.filter(item => item.label !== collectionLabel);
    this.itemsSubject.next(this.returnMenu());
  }
  
}
