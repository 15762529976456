<div class="card c-pointer">
  <div
    class="card__image"
    [ngStyle]="{
      'background-image': bannerImage ? 'url(' + bannerImage + ')' : 'none'
    }"
  >
    <img [src]="profilePicture" />
  </div>
  <div class="card__detail">
    {{ displayName }}
  </div>
</div>
