<div class="card c-pointer" (click)="gotoSeries()">
  <div
    class="card__image"
    [ngStyle]="{
      'background-image': assetSeries?.profile?.bannerImageUrl ? 'url(' + assetSeries?.profile?.bannerImageUrl + ')' : 'none'
    }"
  ></div>
  <div class="card__detail">
   <h3> {{ assetSeries?.assetSeriesName }}</h3>

    <div class="row details">
      <div class="col-6">
        <span>Floor</span>
        <span> {{ assetSeries?.blockchainStats?.currentFloorPrice }}</span>
      </div>
      <div class="col-6">
        <span>Volume</span>
        <span> {{ assetSeries?.blockchainStats?.totalVolume }}</span>
      </div>
      <div class="col-6">
        <span>Series</span>
        <span>{{ assetSeries?.assetSeriesSize }}</span>
      </div>
      <div class="col-6">
        <span>Listed</span>
        <span>{{ listed }}</span>
      </div>
    </div>
    <div *ngIf="isOwner">
      <button class="button button--tertairy-alt" (click)="editSeries()">Edit</button>
      <button class="button button--tertairy-alt" (click)="createAsset()">Create Nft</button>
    </div>
  </div>
</div>
