import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, Observable, Subject } from 'rxjs';
import { IAssetAttribute } from 'src/app/shared/models/assets/asset-attribute';
import { IAssetAttributeType } from 'src/app/shared/models/assets/asset-attribute-type';

export interface filterAttributeType {
  [x : string]: {
    show: boolean,
    attributeTypeName: string,
    attributes: IAssetAttribute[]
  },
}
export interface filterAttributeSelection {
  assetAttributeTypeGuid: string,
  attributeValue: string
}

@Component({
  selector: 'app-asset-series-asset-filter',
  templateUrl: './asset-series-asset-filter.component.html',
  styleUrls: ['./asset-series-asset-filter.component.scss']
})
export class AssetSeriesAssetFilterComponent implements OnInit {

  onSearchTextChanged = new Subject<string>();

  _attributeList: filterAttributeType = {};
 
  showModal: boolean = false;
  
  results$!: Observable<void>;
  currentCollection: string | undefined;

  suppressOnCheckboxChanged: boolean = false;

  _filterSettings : AssetSeriesAssetFilterSettings;
  tempFilterSettings? : AssetSeriesAssetFilterSettings;

  @Input() set
  filterSettings(value: AssetSeriesAssetFilterSettings) {

    this.tempFilterSettings = value;
    this._filterSettings = value;

  }

  @Input() set
  attributeList(value : IAssetAttributeType[] | undefined) {

    this._attributeList = {};
    value?.forEach(element => {

      this._attributeList[element.attributeTypeGuid] = {
        show: false,
        attributeTypeName: element.attributeTypeName,
        attributes: []
      }
      this._attributeList[element.attributeTypeGuid].attributes = element.assetAttributes;
      this._attributeList[element.attributeTypeGuid].attributes.sort((a,b) => {

        if (a.attributeName > b.attributeName) return 1;
        if (a.attributeName < b.attributeName) return -1;

        return 0;

      });

    });

  }

  @Output()
  filter: EventEmitter<AssetSeriesAssetFilterSettings> = new EventEmitter<AssetSeriesAssetFilterSettings>();

  constructor() {

    this._filterSettings = {
      selectedFilters : [],
      attributeTypes: {} as filterAttributeType,
      showMyNfts: false,
      searchText : "",
      isDesktop: false,
      isLoggedIn : false,
      sortByAsc: true,
      sortBy: 'name',
      hasBuyOffers: undefined,
      hasSellOffers: undefined,
      combineAttributesWith: "and",
      canTransferToMe: false,
      filterByCurrency: undefined,
    };

  }

  ngOnInit(): void {
    this.onSearchTextChanged.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(searchText => 
      this.filter.emit(this.filterSettings)
    );
  }

  onCheckboxChange(e: any, emit: boolean) {

    if (this.suppressOnCheckboxChanged) {
      return;
    }

    if (this.tempFilterSettings?.combineAttributesWith == 'and') {

      let checkbox = (e.currentTarget as HTMLInputElement);
      let isChecked = checkbox.checked;

      Array.from(document.querySelectorAll(`.filter .filter__option input[type=checkbox][name="${checkbox.name}"]`)).forEach(e => {
        let element = (e as HTMLInputElement);
        element.checked = element.value == checkbox.value && isChecked;
      })

    }

    let selections = Array.from(document.querySelectorAll('.filter .filter__option input[type=checkbox]:checked'))?.map(_ => {
      let element = (_ as HTMLInputElement);
      return { assetAttributeTypeGuid: element.name, attributeValue: element.value } as filterAttributeSelection;
    });

    this.tempFilterSettings!.selectedFilters = selections;

    if (emit) {
      this._filterSettings = this.tempFilterSettings!;
      this.filter.emit(this._filterSettings!);
    }
    
  }

  onCanTransferToMe(e: any, emit: boolean) {
    this.tempFilterSettings!.canTransferToMe = e.target.checked;

    if (emit) {
      this._filterSettings = this.tempFilterSettings!;
      this.filter.emit(this._filterSettings);
    }

  }

  onShowMyNfts(e: any, emit: boolean) {
    this.tempFilterSettings!.showMyNfts = e.target.checked;

    if (emit) {
      this._filterSettings = this.tempFilterSettings!;
      this.filter.emit(this._filterSettings);
    }

  }
  onShowWithBuyOffersNfts(e: any, emit: boolean) {

    this.tempFilterSettings!.hasBuyOffers = e.target.checked;

    if (emit) {
      this._filterSettings = this.tempFilterSettings!;
      this.filter.emit(this._filterSettings);
    }

  }
  onShowWithSellOffersNfts(e: any, emit: boolean) {

    this.tempFilterSettings!.hasSellOffers = e.target.checked;

    if (emit) {
      this._filterSettings = this.tempFilterSettings!;
      this.filter.emit(this._filterSettings);
    }


  }
  setCombineAttributesWith(combineWith : string, emit: boolean) {

    this.tempFilterSettings!.combineAttributesWith = combineWith;

    if (combineWith == 'and') {

      this.suppressOnCheckboxChanged = true;
      this._filterSettings.selectedFilters.forEach(filter => {

        let foundFirst = false;
        Array.from(document.querySelectorAll(`.filter .filter__option input[type=checkbox][name="${filter.assetAttributeTypeGuid}"]`)).forEach(e => {
          let element = (e as HTMLInputElement);
          element.checked = element.checked && !foundFirst;
          foundFirst ||= element.checked;
        })

      })

      let selections = Array.from(document.querySelectorAll('.filter .filter__option input[type=checkbox]:checked'))?.map(_ => {
        let element = (_ as HTMLInputElement);
        return { assetAttributeTypeGuid: element.name, attributeValue: element.value } as filterAttributeSelection;
      });
  
      this.tempFilterSettings!.selectedFilters = selections;

      this.suppressOnCheckboxChanged = false;

    }

    if (emit) {
      this._filterSettings = this.tempFilterSettings!;
      this.filter.emit(this._filterSettings);
    }

  }

  setSearchText(e: any, emit: boolean) {
    this.tempFilterSettings!.searchText = e.target.value;

    if (emit) {
      this._filterSettings = this.tempFilterSettings!;
      // has a debounce on it
      this.onSearchTextChanged.next(this.tempFilterSettings!.searchText);
    }
  }

  toggleModal() {
    this.showModal = !this.showModal;

    if (this.showModal) {
      setTimeout(() => {
        document.querySelector('.filter-modal .gold-bordered-block')?.scrollTo({top:0,behavior:'smooth'});
      }, 0);
    }
  }

  applyFilters() {

    this._filterSettings = this.tempFilterSettings!;

    this.filter.emit(this._filterSettings);
    this.toggleModal()
  }

  isCurrentlySelected(assetAttributeTypeGuid: string) {
    return this.tempFilterSettings?.selectedFilters.some(_ => _.assetAttributeTypeGuid == assetAttributeTypeGuid);
  }

  cancel() {
    this.tempFilterSettings = this._filterSettings;

    (<HTMLInputElement>document.querySelector('.filter__search > input')).value = this.tempFilterSettings.searchText;

    this.toggleModal();
  }

}

export class AssetSeriesAssetFilterSettings {

  selectedFilters: filterAttributeSelection[] = [];

  searchText : string = "";
  attributeTypes: filterAttributeType = {};

  isDesktop : boolean = false;

  sortByAsc: boolean = true;
  sortBy: string = "name";

  filterByCurrency?: string = "";

  hasSellOffers: boolean | undefined;
  hasBuyOffers: boolean | undefined;

  showMyNfts: boolean = false;
  combineAttributesWith: string = "and";

  canTransferToMe: boolean | undefined;

  isLoggedIn: boolean = false;
  
}
