<div class="qrCode" *ngIf="signInQrCode">
  <p *ngIf="showMessage">
    Scan this code to complete your transaction
  </p>

  <a class="qrCode__link" [href]="signInQrUrl">
    <div class="qrCode__code">
      <img class="qrCode__img" [src]="signInQrCode" />
    </div>
    <p>
      or <br/>
      <span>click/tap here to open with Xumm</span>
    </p>
  </a>
  <button class="button button--tertairy-alt" type="button" (click)="emitCancel()">
    Cancel
  </button>
</div>
