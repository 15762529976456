import { Component, OnInit, Input, EventEmitter, Output,} from '@angular/core';
import { IBlockchainNftOfferDetails } from 'src/app/shared/models/block-chain-nfts/IBlockchainNftOfferDetails';
import { IBlockchainLedgerWalletDetails } from 'src/app/shared/models/xls-20-nfts/blockchain-ledger-wallet-details';
import { AccountService } from 'src/app/shared/services/account.service';
import { SecurityService } from 'src/app/shared/services/security.service';

@Component({
  selector: 'app-xls-20-nft-listing-offer',
  templateUrl: './xls-20-nft-listing-offer.component.html',
  styleUrls: ['./xls-20-nft-listing-offer.component.scss']
})
export class Xls20ListingOfferComponent {

  offer: IBlockchainNftOfferDetails | undefined;
  isSellOffer: boolean = false;
  amount: number = 0;
  currency: string = ""

  currencyIssuer: IBlockchainLedgerWalletDetails | undefined;
  destinationWallet: IBlockchainLedgerWalletDetails | undefined;
  sourceWallet: IBlockchainLedgerWalletDetails | undefined;

  offerId: string = "";

  brokerSale: boolean = false;
  canAccept : boolean = false;
  canCancel: boolean = false;
  index: number = 0;

  interactEnabled: boolean = false;
  isTransfer: boolean = false;

  @Output()
  accepted: EventEmitter<IBlockchainNftOfferDetails> = new EventEmitter<IBlockchainNftOfferDetails>();

  @Output()
  cancelled: EventEmitter<IBlockchainNftOfferDetails> = new EventEmitter<IBlockchainNftOfferDetails>();

  @Input()
  set xls20Offer(value: IBlockchainNftOfferDetails) {
    this.offer = value;
    this.offerId = value.blockchainOfferLedgerId;
    this.isSellOffer = value.isSellOffer;
    this.amount = value.amount;
    this.currency = this.CurrencyFromHexCode(value.currency);
    // this.currencyIssuer = value.currencyIssuerWalletDetails;
    // this.destinationWallet = value.destinationWalletWalletDetails;
    // this.sourceWallet = value.sourceWalletWalletDetails;

    this.brokerSale = this.isSellOffer && (this.destinationWallet?.address != this.sourceWallet?.address);

    this.isTransfer = this.canAccept && value.amount == 0;

  }
  @Input()
  set canInteract(value: boolean) {
    this.interactEnabled =value;
  }

  @Input()
  set userCanAccept(value: boolean) {
    this.canAccept = value;
    this.isTransfer = this.canAccept && this.amount == 0;
  }
  @Input()
  set userCanCancel(value: boolean) {
    this.canCancel = value;
  }

  @Input()
  set Index(value : number) {
    this.index = value;
  }

  constructor(private securityService: SecurityService,
    private accountService : AccountService) { }

  accept() {
    this.accepted.emit(this.offer);
  }

  cancel() {
    this.cancelled.emit(this.offer);
  }

  CurrencyFromHexCode(currency: string)
  {

      if (currency.length <= 3) {
          return currency;
      }

      var hex = currency.toString().replace(/(00)/g, "");
      var str = '';
      for (var i = 0; i < hex.length; i += 2){
          var test = String.fromCharCode(parseInt(hex.substring(i, i+2), 16));
          str += String.fromCharCode(parseInt(hex.substring(i, i+2), 16));
      }
      return str;
  }


}
