<div *ngIf="_filterSettings" class="filter" [ngClass]="{'filter--desktop' : _filterSettings.isDesktop, 'filter--mobile' : !_filterSettings.isDesktop, 'filter--modal' : showModal }">
  <div>
    <div class="filter__search">
      <input type="text" placeholder="Search" (keyup)="setSearchText($event, _filterSettings.isDesktop)" value="{{_filterSettings.searchText}}" />
    </div>
    <div class="filter__main">
      <h2>Filter</h2>
      <div class="filter__options">

        <div *ngIf="_filterSettings.isLoggedIn">
          <div class="filter__child show">
            <div>
              <label for="showMyNfts">Only my NFTs</label>
              <input type="checkbox" [checked]="tempFilterSettings!.showMyNfts" name="showMyNfts" value="showMyNfts" id="showMyNfts" (change)="onShowMyNfts($event, _filterSettings.isDesktop)">
            </div>
          </div>
        </div>

        <div *ngIf="_filterSettings.isLoggedIn">
          <div class="filter__child show">
            <div>
              <label for="canTransferToMe">Can transfer to me</label>
              <input type="checkbox" [checked]="tempFilterSettings!.canTransferToMe" name="canTransferToMe" value="canTransferToMe" id="canTransferToMe" (change)="onCanTransferToMe($event, _filterSettings.isDesktop)">
            </div>
          </div>
        </div>

        <div>
          <div class="filter__child show">
            <div>
              <label for="showSellOffers">Only with sell offers</label>
              <input type="checkbox" [checked]="tempFilterSettings!.hasSellOffers" name="showSellOffers" value="showSellOffers" id="showSellOffers" (change)="onShowWithSellOffersNfts($event, _filterSettings.isDesktop)">
            </div>
          </div>
        </div>

        <div>
          <div class="filter__child show">
            <div>
              <label for="showBuyOffers">Only with buy offers</label>
              <input type="checkbox" [checked]="tempFilterSettings!.hasBuyOffers" name="showBuyOffers" value="showBuyOffers" id="showBuyOffers" (change)="onShowWithBuyOffersNfts($event, _filterSettings.isDesktop)">
            </div>
          </div>
        </div>

        <div class="filter__attributes">
          <div class="filter__type">
            <span>Traits</span>
            <div class="options">
              <div [class]="{ selected : tempFilterSettings!.combineAttributesWith === 'or'}" (click)="setCombineAttributesWith('or', _filterSettings.isDesktop)">OR</div>
              <div [class]="{ selected : tempFilterSettings!.combineAttributesWith === 'and'}" (click)="setCombineAttributesWith('and', _filterSettings.isDesktop)">AND</div>
            </div>
          </div>

          <div class="filter__attribute-type glass glass--small-radius" *ngFor="let item of _attributeList | keyvalue">
            <h5 (click)="item.value.show = !item.value.show">
              {{item.value.attributeTypeName}}
              <i *ngIf="!item.value.show" class="fa-solid fa-angle-up"></i>
              <i *ngIf="item.value.show" class="fa-solid fa-angle-down"></i>
            </h5>
            <div [ngClass]="{ show : item.value.show }" class="filter__child">
              <div *ngFor="let value of item.value.attributes" class="filter__option">
                <label for="{{value.attributeGuid}}">{{value.attributeName}}</label>
                <input type="checkbox" [checked]="isCurrentlySelected(value.attributeGuid)" [name]="item.key" value="{{value.attributeGuid}}" id="{{value.attributeGuid}}" (change)="onCheckboxChange($event, _filterSettings.isDesktop)">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <button *ngIf="!_filterSettings.isDesktop" type="button" class="button" (click)="cancel()">Cancel</button>
    <button *ngIf="!_filterSettings.isDesktop" type="button" class="button" (click)="applyFilters()" >Apply</button>
  </div>
</div>
