import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent {
  currentRoute = '';
  routes: { regex: RegExp; backRoute?: string }[] = [
    {
      regex: /^\/profile\/[0-9]+\/edit$/,
    },
    {
      regex: /\/collection\//,
      backRoute: '/explore/collections',
    },
    {
      regex: /\/nft\//,
    },
    {
      regex: /^\/profile\/[0-9]+\/[a-zA-z]+/,
      backRoute: '/explore/profiles',
    },
  ];
  show: boolean = false;

  constructor(private router: Router, private location: Location) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const newRoute = event.url;
        if (this.currentRoute !== newRoute) {
          this.setupVisibility();
        }
      }
    });
  }

  setupVisibility() {
    this.currentRoute = this.router.url;
    this.show = this.routes.some((route) =>
      route.regex.test(this.currentRoute)
    );
  }

  goBack() {
    const route = this.routes.find((route) =>
      route.regex.test(this.currentRoute)
    );
    if (route?.backRoute) {
      this.router.navigate([route.backRoute]);
    } else {
      this.location.back();
    }
  }
}
