import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateAddress',
})
export class TruncateAddressPipe implements PipeTransform {
  transform(
    value: string | undefined,
    start: number = 19,
    end: number = 3
  ): string | undefined {
    if (!value) return value;

    if (start === 0 && end === 0) return value;
    if (start > value.length) return value;
    if (end > value.length) return value;

    const truncatedValue = `${value.substring(0, start)}...${value.substring(
      value.length - end
    )}`;

    return truncatedValue;
  }
}
