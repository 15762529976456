

<div class="xls-20-nft-listing-offer" [ngClass]="{ 'first' : index === 0 }">

  <div class="amount">
    <div class="total">{{amount}} {{currency}}</div>
    <div class="issuer">{{currencyIssuer | BlockchainWalletName}}</div>
    <div class="from">To: {{sourceWallet | BlockchainWalletName}}</div>
  </div>

  <div class="offer">
    <button *ngIf="!isTransfer && !canCancel && canAccept && interactEnabled" class="button button--tertairy-alt" [ngClass]="{ 'small' : index > 0 }" (click)="accept()">
      Accept
    </button>
    <button *ngIf="isTransfer && interactEnabled" class="button button--tertairy-alt" [ngClass]="{ 'small' : index > 0 }" (click)="accept()">
      Transfer
    </button>
    <button *ngIf="canCancel && interactEnabled" class="button button--tertairy-alt" [ngClass]="{ 'small' : index > 0 }" (click)="cancel()">
      Cancel
    </button>
    <div *ngIf="false && brokerSale" class="broker-sale">
      Broker sale
    </div>
  </div>

</div>
