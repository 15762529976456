import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageLayoutComponent } from './page-layout/page-layout.component';

const routes: Routes = [
  {
    path: '',
    component: PageLayoutComponent,
    children: [
      {path:'', redirectTo: 'explore', pathMatch: 'full'},
      {
        path: 'home',
        loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'statistics',
        loadChildren: () => import('./features/statistics/statistics.module').then(m => m.StatisticsModule),
      },
      {
        path: 'collections',
        loadChildren: () => import('./features/collections/collection.module').then(m => m.CollectionModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('./features/profile/profile.module').then(m => m.ProfileModule),
      },
      {
        path: 'explore',
        loadChildren: () => import('./features/explore/explore.module').then(m => m.ExploreModule),
      },
      {
        path: 'nft',
        loadChildren: () => import('./features/nft/nft.module').then(m => m.NftModule),
      },
      {
        path: 'reset',
        loadChildren: () => import('./features/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
      },
      {
        path: 'launchpad',
        loadChildren: () => import('./features/launchpad/launchpad.module').then(m => m.LaunchpadModule),
      },
      // {
      //   path: 'account',
      //   loadChildren: () => import('./features/account/account.module').then((m) => m.AccountModule),
      // },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
