import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SecurityService } from 'src/app/shared/services/security.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IPaymentInstrument } from '../../models/account';
import { MarketplaceService } from 'src/app/shared/services/marketplace.service';
import { NftCancelOfferProgressStatus } from '../../models/marketplace/nft-cancel-offer';
import { NoXummModalComponent } from '../no-xumm-modal/no-xumm-modal.component';
import { IPremintedAsset1 } from '../../models/IPremintedAsset';

@Component({
  selector: 'app-nft-mint-modal',
  templateUrl: './nft-mint-modal.component.html',
  styleUrls: ['./nft-mint-modal.component.scss']
})
export class NftMintModalComponent implements OnInit,AfterViewInit {
  @ViewChild('appCancelOfferModal') cancelOfferModalRef?: ElementRef;
  @ViewChild(NoXummModalComponent) noXummModalComponent: NoXummModalComponent | undefined;


  hasXumm: boolean = false;
  waitingMessage: string | undefined;
  slideIndex: number = 0;
  isInteracting = false;
  
  xummInteractionQrUrl: SafeResourceUrl | undefined;
  xummInteractionQrCode: string | undefined;

  cancelOfferModal: any;
  
  currentPaymentInstruments : IPaymentInstrument[] | undefined;

 
  @Input()
  set HasXumm(value: boolean) {
    this.hasXumm = value;
  }

  @Input()
  set CurrentPaymentInstruments(value: IPaymentInstrument[] | undefined) {
    this.currentPaymentInstruments = value;
  }

  
  @Output()
  nftMinted: EventEmitter<IPremintedAsset1> = new EventEmitter<IPremintedAsset1>();
  
  constructor(
    private marketplaceService: MarketplaceService,
    private securityService: SecurityService,
    private window: Window,
    private sanitizer: DomSanitizer,
  ) { 
  }

  ngOnInit(): void {
    
  }
  
  ngAfterViewInit() {
    this.cancelOfferModal = new this.window.bootstrap.Modal(this.cancelOfferModalRef?.nativeElement, {
      backdrop: 'static',
      keyboard: false
    });
  }
  
  mintNft(nft: IPremintedAsset1) {
    if (!this.securityService.isSignedIn) {
      return;
    }
    
    if (!this.hasXumm) {
      this.noXummModalComponent?.show();
      return;
    }

    this.waitingMessage = 'CREATING QR CODE';
    this.slideIndex = 1;
    this.isInteracting = true;
    this.cancelOfferModal.show();

    this.securityService.getAccountDetails().subscribe(accountDetailsResponse => {

      if (accountDetailsResponse.data == null) {
        this.cancelXummInteraction();
        console.log("you need to be logged in to use the marketplace");
        return;
      }

      let account = accountDetailsResponse.data;
      let paymentInstrument = this.currentPaymentInstruments;
      this.marketplaceService.mintXls20Nft(nft.assetGuid, nft.blockchainProviderGuid).subscribe(notificationResult => {

        if (notificationResult.immediateResponse) {
          this.xummInteractionQrCode = notificationResult.immediateResponse.data.qrCodeImage;
          this.xummInteractionQrUrl = this.sanitizer.bypassSecurityTrustResourceUrl(notificationResult.immediateResponse.data.qrCodeUrl);
          this.waitingMessage = undefined;
          this.slideIndex = 0;

          let currentProgress = 0;
          notificationResult.subscribe(result => {
            this.waitingMessage = 'Please Wait';

            switch(result.progressStatus) {
              case NftCancelOfferProgressStatus.Complete:

                if (currentProgress <= 1) {
                  this.waitingMessage = 'NFT OFFER CANCELLED!';
                  this.slideIndex = 1;

                  setTimeout(() => {
                    this.nftMinted.emit(nft);
                    this.cancelXummInteraction();
                  }, 2000)

                  currentProgress = 2;
                }
                break;
              case NftCancelOfferProgressStatus.Failed:
                this.waitingMessage = `CANCEL OFFER FAILED`;
                this.slideIndex = 1;

                setTimeout(() => {
                  this.cancelXummInteraction();
                }, 2000)

                break;
            }
          });

        }

      });

    })

  }




  cancelXummInteraction() {
    this.isInteracting = false;
    this.waitingMessage = undefined;
    this.slideIndex = 0;
    this.cancelOfferModal.hide();
  }

}


