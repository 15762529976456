import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blockchain-collection-card',
  templateUrl: './blockchain-collection-card.component.html',
  styleUrls: ['./blockchain-collection-card.component.scss'],
})
export class BlockchainCollectionCardComponent {

  @Input()
  collectionGuid?: string;

  @Input()
  name?: string;

  @Input()
  image?: string;

  @Input()
  isFavorite?: boolean;

  @Input()
  floor?: number;

  @Input()
  volume?: number;

  // NFT Count
  @Input()
  totalSeries?: number;

  @Input()
  listed?: number;

  @Input()
  isPreMint: boolean = false;

  @Input()
  profileGuid?: string;

  constructor(private router: Router) {}

  gotoCollection () {
    if(this.isPreMint && this.profileGuid) {
      this.router.navigate([`/profile/${this.profileGuid}/created/premint/${this.collectionGuid as string}`]);
    }
  }
}
