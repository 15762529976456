export const environment = {
  production: false,
  apiUrl: 'https://beta-api.xspectar.com/api/tenant/6C9D1300-D4B6-4835-A3E6-302B91AD06C2/',
  providerApiUrl: 'https://beta-api.xspectar.com/api/',
  videoUrl: 'https://xspectarnfts.blob.core.windows.net/assets/video/intro_',
  sceneRootUrl: 'https://xspectarnfts.blob.core.windows.net/assets/geom/',
  marketingSite: 'https://beta.xspectar.com/',
  tenantGuid: '6C9D1300-D4B6-4835-A3E6-302B91AD06C2',
  xummProviderGuid: '363B0511-C6AD-4F25-A6F9-3A9E4B03BD80',
  xUserProviderGuid: 'A44561B9-F81B-402E-AD29-E7EA596B92ED',
  stripeProviderGuid: '5249ECA6-DB63-47F2-AF43-FCA285C3552B',
  xrplBlockchainProviderGuid: '619AD4CA-522B-450F-BB7A-8D1446827624',
  hederaProviderGuid : '1e12027a-6eb1-4fa1-8be4-68edda66cc04',
  xrpCurrencyGuid: '19963576-eb78-4e54-a48d-4e210b517836',
  xSpectarCurrencyGuid: '82e40ca4-9511-4cd9-8d32-1fa406b70cf0',
  usdCurrencyGuid: '657ca3d0-0b71-4d5b-acf8-126b00b616cf',
  agentListingGuid: '40009863-519a-4bda-8dcf-4d94cde3ee67',
  unlockListingGuid: '849fc0a9-e2e6-4cc4-92c8-6e5ded16e407',
  agent444ListingGuid: '604be37f-d4ff-47af-9779-d74666ca56bb',
  unlockAssetSeriesGuid: '90552bd2-a09f-46fa-8021-07c5b9175111',
  agentAssetSeriesGuid: '52e1a144-0f4c-4518-b292-96c356d4e236',
  the444at444AssetSeriesGuid: 'a63d5797-4dc6-428a-b987-66b1c89fac5e',
  vaultAssetCollectionGuid: '3fca3f9e-b376-42fb-9400-5e56468627b0',
  giveawaysAssetCollectionGuid: '9196170f-2195-4958-8388-8d319ce30285',
  landSalePlotsAssetCollectionGuid: '88de15cd-fce6-49fd-a19c-ac71844d6790',
  the88agentAssetSeriesGuid : 'b695f8cf-608d-4f7c-817c-d1e2910d2be8',
  freeKangaAssetSeriesGuid: 'f90dc622-798e-442f-abc7-8234ace4c7f5',
  transactionStatusRefundedGuid : 'd99099b5-1cfa-4454-be91-cb99ab61f4dd',
  tomniaProviderGuid: '9e9ecb88-23d8-40ac-9e9e-8e8db6b44372',

  goldLandSeriesGuid: 'ede16e32-47f8-41f8-a82b-5ff70cacc54e',
  silverLandSeriesGuid: '12ef0303-c54c-413d-8e23-97530f769da6',
  bronzeLandSeriesGuid: '4883aece-c9d0-4246-a707-389bdd3ee015',
  freeLandSeriesFromNftPreMintGuid: '7ec17d91-f6dc-40c2-8331-0affe5cd06a1',
  freeLandSeriesFromPackagePreMintGuid: '9c76614f-6747-429b-bba5-4c40133c167f',

  assetTyprewardAssetTypeGuid: 'CEBFF9B7-F891-42EA-9F06-A9197ADF8C5B'.toLocaleLowerCase(),
  featureUnlockAssetTypeGuid : '3AE302CF-AEDB-493B-B693-764E78C17707'.toLocaleLowerCase(),
  rewardAssetTypeGuid: 'CEBFF9B7-F891-42EA-9F06-A9197ADF8C5B'.toLocaleLowerCase(),
  tokenStakeAssetTypeGuid: 'A487E7BD-13F0-4367-BFD0-D5EE90ACDBC3'.toLocaleLowerCase(),
  mintedUnclaimedAssetTypeGuid: 'D45DA9F5-6E7B-4CD2-89E1-0415BC1554E4'.toLocaleLowerCase(),
  mintedAssetTypeGuid: 'E1257F7F-6D51-4590-BD46-C40281E805F5'.toLocaleLowerCase(),

  activeAssetStatusGuid : '6e8776ba-1a72-4f56-b743-0881b47b73d0',
  refundedAssetStatusGuid : '30e7635a-5bda-4767-9691-629cc8bbdc35',
  soldAssetStatusGuid : '73cdf89f-7669-43b3-9708-f5ff63eeaf97',
  transferedAssetStatusGuid : '84e96722-6cec-489e-b1e9-3d9740919569',
  blockedAssetStatusGuid : '7138a722-d5cb-4adf-92ce-80ab085f23b2',
  redeemedAssetStatusGuid : '519793bc-9d46-4646-9885-f99465b02d0a',
  redeemingAssetStatusGuid : '8409a7f2-16a3-4983-91ba-09ba5dd7b834',
  redeemFaileAssetStatusGuid : '50bc9d06-d25c-4d9f-bc52-8518d1c5962c',

  securityHubTypeGuid : 'ea23ee8a-476f-44dd-86a6-5805e8975d27',
  notiforcationsHubTypeGuid : '5622bfcd-3e26-48eb-9715-066296707906',

  assetTypeDigitalAsset: 'F0A7A6D3-6669-4002-9CDD-A8052F188771'.toLocaleLowerCase(),
  assetTypeNftPreMint: '2A00522B-2142-424B-9639-8B2B5B9BEC33'.toLocaleLowerCase(),
  assetTypeNftMinted: 'E1257F7F-6D51-4590-BD46-C40281E805F5'.toLocaleLowerCase(),
  assetTypeNftPreMintFixedPrice: 'C22A5DBC-6945-4D11-9950-206EBE665D43'.toLocaleLowerCase(),
  assetTypeNftMintedRandom: '74FFD201-31D5-48DF-8BBE-D973DE494CB7'.toLocaleLowerCase(),
  assetTypeNftHidden: 'CD6DA6BF-31AB-416C-829E-2DBDC833F08D'.toLocaleLowerCase(),
  assetTypeNftMintedUnclaimed:'D45DA9F5-6E7B-4CD2-89E1-0415BC1554E4'.toLocaleLowerCase(),

  sentryDsn: 'https://3380435def56411c86e07b27a042a08f@o4504026656538624.ingest.sentry.io/4504026660012032',

  xSpectarIssuerWallet : "ra59pDJcuqKJcQws7Xpuu1S8UYKmKnpUkW",
  dsCollectionIssuerWallet : "rJcuzN4WAwCZMxxhff5YmX2r4WjwLK4ujT",
  xSpectar8888Name : "XSpectar Agents",
  xSpectar88Name : "xSpectar Agent 88",
  dsCollectionName : "The Ds Collection",

  stripePublicKey: 'pk_test_51MehSmElEKcoI9DBKLuPffz36sDGsg0iSMMWPx1zBL56y8zsVE50ehlNleRXNwZBgj488EHK6QEjyvSZsf48cnH6006m3KugLJ',
  storageContainerUrl: 'https://xrplnfts.blob.core.windows.net/',

  
  alternateCurrenyProviders: ["657CA3D0-0B71-4D5B-ACF8-126B00B616CF".toLocaleLowerCase()] // USD

};
