import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe(routeEvent => {
      if (routeEvent instanceof NavigationEnd) {
        this.document.querySelector('.allow-scroll')?.scroll(0,0);
      }
    });
  }
}
