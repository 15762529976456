<div class="card c-pointer" (click)="gotoCollection()">
  <div
    class="card__image"
    [ngStyle]="{
      'background-image': image ? 'url(' + image + ')' : 'none'
    }"
  ></div>
  <div class="card__detail">
   <h3> {{ name }}</h3>

    <div class="row details">
      <div class="col-6">
        <span>Floor</span>
        <span> x {{ floor }}</span>
      </div>
      <div class="col-6">
        <span>Volume</span>
        <span> x {{ volume }}</span>
      </div>
      <div class="col-6">
        <span>Series</span>
        <span>{{ totalSeries }}</span>
      </div>
      <div class="col-6">
        <span>Listed</span>
        <span>{{ listed }}</span>
      </div>
    </div>
  </div>
</div>
