import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityService } from '../services/security.service';
import { AuthGuard } from '../guards/auth-guard';

@Injectable({
  providedIn: 'root',
})
export class SecurityInterceptor implements HttpInterceptor {

    constructor(
      private securityService: SecurityService,
      private router: Router,
      private activatedRoute: ActivatedRoute
    ) {
      }

    intercept(httpRequest: HttpRequest<any>, next: HttpHandler) : Observable<HttpEvent<any>> {
      if (this.securityService.isSignedIn && this.securityService.securityToken) {
        return next.handle(httpRequest.clone({
          headers: httpRequest.headers.set('Authorization', this.securityService.securityToken)
        })).pipe(
          catchError((error: any) => {
            return this.handleError(error);
          })
        );
      }

      return next.handle(httpRequest);
    }

  private handleError(error: any) {
    if (error instanceof HttpErrorResponse && error.status === 401) {
      const routeConfig = this.activatedRoute.snapshot.routeConfig;
      this.securityService.signOut(routeConfig?.canActivate?.includes(AuthGuard) as boolean);
    }
    return throwError(() => new Error(error));
  }
}
