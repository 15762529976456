import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SecurityService } from './services/security.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SecurityInterceptor } from './interceptors/security-interceptor';
import { SlideComponent } from './components/slide/slide.component';
import { ScanQrCodeComponent } from './components/scan-qr-code/scan-qr-code.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { RegisterComponent } from './components/register/register.component';
import { WaitingComponent } from './components/waiting/waiting.component';
import { CurrencyAmountPipe } from './pipes/currency-amount.pipe';
import { IconComponent } from './components/icon/icon.component';
import { SecurityDetailsComponent } from './components/security-details/security-details.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { BlockchainWalletNamePipe } from './pipes/blockchain-wallet-name.pipe';
import { NavigationComponent } from './components/navigation/navigation.component';
import { FavouriteComponent } from './components/favourite/favourite.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AssetCollectionCardComponent } from './components/cards/asset-collection-card/asset-collection-card.component';
import { AssetSeriesCardComponent } from './components/cards/asset-series-card/asset-series-card.component';
import { ProfileCardComponent } from './components/cards/profile-card/profile-card.component';
import { NftCardComponent } from './components/cards/nft-card/nft-card.component';
import { Xls20FilterComponent } from './components/nft/xls-20-filter/xls-20-filter.component';
import { Xls20ListingComponent } from './components/nft/xls-20-nft-listing/xls-20-nft-listing.component';
import { XlsCollectionComponent } from './components/nft/xls-20-collection/xls-20-collection.component';
import { Xls20MakeOfferComponent } from './components/nft/xls-20-make-offer/xls-20-make-offer.component';
import { Xls20ListingOfferComponent } from './components/nft/xls-20-nft-listing-offer/xls-20-nft-listing-offer.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { AlertComponent } from './components/alert/alert.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { NftOfferCancelModalComponent } from './components/nft-offer-cancel-modal/nft-offer-cancel-modal.component';
import { NftMintModalComponent } from './components/nft-mint-modal/nft-mint-modal.component';
import { NoXummModalComponent } from './components/no-xumm-modal/no-xumm-modal.component';
import { TruncateAddressPipe } from './pipes/truncate-address.pipe';
import { BlockchainCollectionCardComponent } from './components/cards/blockchain-collection-card/blockchain-collection-card.component';
import { AssetSeriesAssetsComponent } from './components/assets/asset-series-assets/asset-series-assets.component';
import { AssetSeriesAssetFilterComponent } from './components/assets/asset-series-assets/asset-series-asset-filter/asset-series-asset-filter.component';
import { AssetListingComponent } from './components/assets/asset-listing/asset-listing.component';

export function initializeSecurityService(securityService: SecurityService) {
  return () => {
    return securityService.initialize();
  };
}

@NgModule({
  declarations: [
    SlideComponent,
    ScanQrCodeComponent,
    SignInComponent,
    RegisterComponent,
    WaitingComponent,
    CurrencyAmountPipe,
    IconComponent,
    SecurityDetailsComponent,
    CheckboxComponent,
    BlockchainWalletNamePipe,
    NavigationComponent,
    FavouriteComponent,
    AssetCollectionCardComponent,
    AssetSeriesCardComponent,
    BlockchainCollectionCardComponent,
    ProfileCardComponent,
    NftCardComponent,
    Xls20FilterComponent,
    Xls20ListingComponent,
    Xls20ListingOfferComponent,
    Xls20MakeOfferComponent,
    XlsCollectionComponent,
    BackButtonComponent,
    AlertComponent,
    NftOfferCancelModalComponent,
    NftMintModalComponent,
    NoXummModalComponent,
    TruncateAddressPipe,

    AssetSeriesAssetsComponent,
    AssetSeriesAssetFilterComponent,
    AssetListingComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    InfiniteScrollModule,
    FormsModule,
    AlertModule.forRoot(),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeSecurityService,
      deps: [SecurityService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SecurityInterceptor,
      deps: [SecurityService],
      multi: true,
    },
  ],
  exports: [
    SlideComponent,
    ScanQrCodeComponent,
    SignInComponent,
    RegisterComponent,
    WaitingComponent,
    CurrencyAmountPipe,
    IconComponent,
    SecurityDetailsComponent,
    CheckboxComponent,
    BlockchainWalletNamePipe,
    NavigationComponent,
    InfiniteScrollModule,
    AssetCollectionCardComponent,
    AssetSeriesCardComponent,
    BlockchainCollectionCardComponent,
    ProfileCardComponent,
    NftCardComponent,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    Xls20FilterComponent,
    Xls20ListingComponent,
    Xls20ListingOfferComponent,
    Xls20MakeOfferComponent,
    XlsCollectionComponent,
    BackButtonComponent,
    AlertComponent,
    NftOfferCancelModalComponent,
    NftMintModalComponent,
    NoXummModalComponent,
    TruncateAddressPipe,
    AssetSeriesAssetsComponent,
    AssetSeriesAssetFilterComponent,
    AssetListingComponent
  ],
})
export class SharedModule {}
