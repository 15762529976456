<div class="card c-pointer" (click)="gotoCollection()">
  <div
    class="card__image"
    [ngStyle]="{
      'background-image': assetCollection?.profile?.bannerImageUrl ? 'url(' + assetCollection?.profile?.bannerImageUrl + ')' : 'none'
    }"
  ></div>
  <div class="card__detail">
    <h3> {{ assetCollection?.assetCollectionName }}</h3>
    <div class="row details">
      <div class="col-6">
        <span>Series</span>
        <span>{{ assetCollection?.totalSeries }}</span>
      </div>
    </div>
    <div *ngIf="isOwner">
      <button class="button button--tertairy-alt" (click)="editCollection($event)">Edit</button>
      <button class="button button--tertairy-alt" (click)="createAssetSeries($event)">Create Series</button>
    </div>
  </div>
</div>
