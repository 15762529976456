import { Component, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MenuService } from '../shared/services/menu-service';
import { MenuServiceMenuItem, MenuServiceMenuItemCollection } from '../shared/models/menu-service-menu-item';
import { SecurityService } from '../shared/services/security.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent  {

  environment: any;

  @Input()
  navigationOpen = false;

  @Output()
  closeEvent = new EventEmitter<boolean>();

  showLogin: boolean = false;
  menuItemCollections: MenuServiceMenuItemCollection[] = [];

  mainMenuCollecion: MenuServiceMenuItemCollection | undefined;

  constructor(private menuService: MenuService, private securityService: SecurityService, private router: Router) {
    this.environment = environment;
    this.setupMenu();
  }

  setupMenu() {

    this.menuService.getItems().subscribe(async menuItems => {

      console.log(menuItems);
      this.menuItemCollections = menuItems;

    });

  }

  selectMenuItem(menuItem: MenuServiceMenuItem) {
    this.router.navigate([menuItem.route]);
    this.closeNavbar();
  }

  ngOnInit(): void {
  }

  closeNavbar() {
    this.closeEvent.emit();
  }


}
