<header class="header">
  <div class="header__left">
    <i class="fa-solid fa-bars" (click)="navigationOpen = !navigationOpen"></i>
    <a routerLink="/home">
      <img src="../../../assets/images/logo.svg" alt="xSpectar" />
    </a>
    <app-back-button></app-back-button>
  </div>
  <div class="header__middle">
    <nav>
      <ul>
        <li>
          <a routerLink="explore">Explore</a>
        </li>
        <li>
          <a routerLink="statistics">Statistics</a>
        </li>
        <li class="d-none">
          <a [href]="aboutUsUrl">About us</a>
        </li>
      </ul>
    </nav>
  </div>
  <div class="header__right">
    <div *ngIf="isSignedIn" class="account-section" [ngClass]="{'account-section--show': showAccountDetails}">
      <span>
        <i class="fa-solid fa-circle-user" (click)="showAccountDetails = !showAccountDetails"></i>
        <span class="logged-in"></span>
      </span>

      <div class="account-section__details">
        <strong *ngIf="userName">{{ userName }}</strong>
        <span *ngIf="userEmail" class="account-section__email">{{ userEmail }}</span>

        <a [routerLink]="['/profile',profileInfo?.profileGuid]" class="button button--tertairy" (click)="showAccountDetails = !showAccountDetails">Go to account</a>
        <a href="#" (click)="signOut()"><i class="fa-solid fa-arrow-right-from-bracket"></i>Sign out</a>
      </div>
    </div>

    <a *ngIf="!isSignedIn" class="button button--tertairy-alt" (click)="signIn()">Sign in</a>
  </div>
</header>

<app-navbar [navigationOpen]="navigationOpen" (closeEvent)="navigationOpen = false"></app-navbar>
