import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  Input,
  AfterViewInit,
} from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { IPaymentInstrument } from 'src/app/shared/models/account/payment-instrument';
import { NftAcceptOfferProgressStatus } from 'src/app/shared/models/marketplace';
import { NftAcceptBrokeredOfferProgressStatus } from 'src/app/shared/models/marketplace/nft-accept-brokered-offer';
import { NftCancelOfferProgressStatus } from 'src/app/shared/models/marketplace/nft-cancel-offer';
import { IXls20NftDetails} from 'src/app/shared/models/xls-20-nfts';
import { AccountService } from 'src/app/shared/services/account.service';
import { MarketplaceService } from 'src/app/shared/services/marketplace.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { IXls20NftOffersUpdate } from 'src/app/shared/services/notifications/messages/xls20-nft-offers-update';
import { NotificationType } from 'src/app/shared/services/notifications/notification-type';
import { SecurityService } from 'src/app/shared/services/security.service';
import { UiService } from 'src/app/shared/services/ui.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-xls-20-nft-listing',
  templateUrl: './xls-20-nft-listing.component.html',
  styleUrls: ['./xls-20-nft-listing.component.scss'],
})
export class Xls20ListingComponent {
  @ViewChild('container')
  container!: ElementRef;

  nft: IXls20NftDetails = null!;

  fullTokenId: string = '';
  tokenId: string = '';
  taxon: string = '';
  name: string = '';
  ranking: string | undefined;
  media: string = '';
  hasVideo: boolean = false;

  showDetail: boolean = false;

  @Input()
  set xls20Nft(value: IXls20NftDetails | undefined) {
    this.nft = value!;

    this.name = value?.name ?? "";
    if (value?.video) {
      this.hasVideo = value?.video.length > 0;
    }

    
    if (value?.hostedResource) {
      this.media = value?.hostedResource;
    } else if (value?.video) {
      this.media = value?.video;
    } else if (value?.issuerWalletDetails.address == 'rJcuzN4WAwCZMxxhff5YmX2r4WjwLK4ujT') {
      this.media = 'https://xspectarnfts.blob.core.windows.net/ds-collection/' + value?.image?.split('/')[5].split('.')[0] + '.jpg'
    } else {
      
      setTimeout(() => {

        this.media = value?.image ?? "";

        if (this.media.startsWith('ipfs://')) {
          this.media = this.media.replace('ipfs://', 'https://cloudflare-ipfs.com/ipfs/')
        } else if (!this.media.startsWith('http://') || !this.media.startsWith('https://') ) {
          this.media = 'https://cloudflare-ipfs.com/ipfs/' + this.media
        }
      }, Math.floor(Math.random() * (1000 - 100 + 1) + 100))

    }
    

    

    this.tokenId = value?.nftokenId ?? "";
    this.fullTokenId = this.tokenId;
    if (this.tokenId.length > 10) {
      this.tokenId = this.fullTokenId.substring(0, 5) + "..." + this.fullTokenId.substring(this.fullTokenId.length - 5)
    }

    this.taxon = value?.taxon ?? "";

    if (value?.attributes)
    {
      this.ranking = value?.attributes.filter(e => e.name == "Ranking")[0]?.value
    }

  }

  constructor(private uiService: UiService, private router: Router) {}

  signIn() {
    this.uiService.openSignInModal(false);
  }

  gotoNftDetail(e: any) {
    e.stopPropagation();
    this.router.navigate(['/nft', this.nft.nftokenId]);
  }
}
