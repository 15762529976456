import { Component, OnInit, Input, ViewChild} from '@angular/core';
import { Observable, of } from 'rxjs';
import { IBlockchainNftAttributeCollectionSummary } from 'src/app/shared/models/xls-20-nfts/blockchain-nft-attribute-collection-summary';
import { IFindCollectionNftsRequest } from 'src/app/shared/models/xls-20-nfts/find-collection-nfts-request';
import { FilterSettings, NftFilterItem, Xls20FilterComponent } from '../xls-20-filter/xls-20-filter.component';
import { DisplayType } from 'src/app/shared/enums/display-type';
import { IXls20NftDetails } from 'src/app/shared/models/xls-20-nfts';
import { SecurityService } from 'src/app/shared/services/security.service';
import { AccountService } from 'src/app/shared/services/account.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { NotificationType } from 'src/app/shared/services/notifications/notification-type';
import { MarketplaceService } from 'src/app/shared/services/marketplace.service';
import { IPaymentInstrument } from 'src/app/shared/models/account';
import { IXls20NftOwnerUpdate } from 'src/app/shared/services/notifications/messages/xls20-nft-owner-update';
import { ServiceResult } from 'src/app/shared/services/results/service-result';
import { INftCollectionResult } from 'src/app/shared/models/xls-20-nfts/nft-collection-result';

@Component({
  selector: 'app-xls-20-collection',
  templateUrl: './xls-20-collection.component.html',
  styleUrls: ['./xls-20-collection.component.scss']
})
export class XlsCollectionComponent implements OnInit {

  @ViewChild(Xls20FilterComponent) filterComp!: Xls20FilterComponent;

  private _collectionName: string | undefined;
  private _currentDisplayType: DisplayType = DisplayType.Grid;

  hasXumm: boolean = false;
  loaded : boolean = false;
  nfts : IXls20NftDetails[] | undefined;
  visibleNfts : IXls20NftDetails[] | undefined;
  interactingListingId : string | undefined;
  currentPaymentInstruments : IPaymentInstrument[] | undefined;
  offerNft: IXls20NftDetails | undefined;
  page: number = 1;
  totalNfts: number = 0;
  selectedFilterIds: number[] | undefined;
  noNfts: boolean = true;

  currentStart: number = 0;
  showOnlyMyNfts: boolean = false;
  pageSize : number = 50;
  attributes: IBlockchainNftAttributeCollectionSummary[] | undefined;

  filterSettings : FilterSettings;
  loadingResults: boolean = true;
  isFilterVisible: boolean = true;
  displayType = DisplayType;
  public throttle = 300;
  public scrollDistance = 1;

  set currentDisplayType(displayType : DisplayType) {
    this._currentDisplayType = displayType;
  }

  get currentDisplayType() : DisplayType {
    return this._currentDisplayType;
  }

  get hasCollectionName(): boolean {
    return this._collectionName != undefined && this._collectionName != '';
  }

  @Input() set collectionName(name :string | undefined) {
    this._collectionName = name;

    if (this._collectionName == 'xSpectar Agent 88') {
      this.pageSize = 20
    }
    this.visibleNfts = [];
    this.getNftsByCollection(this.page);
  }

  @Input()
  set HasXumm(value : boolean) {
    this.hasXumm = value;
  }

  constructor(private securityService: SecurityService,
    private accountService: AccountService,
    private marketplaceService: MarketplaceService,
    private notificationService: NotificationService) {

      this.filterSettings = this.getDefaultSearch();

      notificationService.listenFor(NotificationType.SignOut).subscribe(() => {
        this.setupNfts();
      });
      notificationService.listenFor(NotificationType.SignInConfirmed).subscribe(() => {
        this.setupNfts();
      });

  }

  ngOnInit(): void {

    window.onresize = () => this.filterSettings.isDesktop = !(window.innerWidth < 800);
    this.filterSettings.isDesktop = !(window.innerWidth < 800);

    if(!this._collectionName || this._collectionName === ''){
      this.getNftsByCollection(this.page);
    }
  }

  getPaymentInstruments() : Observable<ServiceResult<IPaymentInstrument[]>> {

    this.filterSettings.isLoggedIn = this.securityService.isSignedIn;
    if (!this.securityService.isSignedIn) {
      return of(new ServiceResult<IPaymentInstrument[]>())
    }

    return this.accountService.getAccountPaymentInstruments();
  }

  getNftsByCollection(pageNumber: number = 0) {

    this.loadingResults = true;

    this.page = pageNumber;
    let findParams : IFindCollectionNftsRequest = {
       collectionName : this._collectionName ?? "",
       from : (pageNumber-1) * this.pageSize,
       take : this.pageSize,
       searchBy : this.filterSettings.searchText ?? "",
       filterBy : this.selectedFilterIds ?? [],
       owner: this.filterSettings.showMyNfts && this.currentPaymentInstruments && this.currentPaymentInstruments.length > 0 ? this.currentPaymentInstruments[0].externalReference : "",
       hasNoMarketplaceOwner: this.filterSettings.showTresuryNfts ? true : undefined,
       sortByAsc: this.filterSettings.sortByAsc,
       sortByRanking: this.filterSettings.sortByRanking,
       hasBuyOffers: this.filterSettings.hasBuyOffers,
       hasSellOffers: this.filterSettings.hasSellOffers,
       combineAttributesWith: this.filterSettings.combineAttributesWith,
       canTransferToMe: this.filterSettings.canTransferToMe && this.currentPaymentInstruments != undefined,
       sortByBids : this.filterSettings.sortByBids,
       currency : this.filterSettings.currency,
    }

    if (findParams.canTransferToMe) {
      findParams.owner = this.currentPaymentInstruments![0].externalReference
    }

    if(!this._collectionName || this._collectionName===''){
      this.marketplaceService.findXls20Nfts(findParams).subscribe(this.handleNftResponse.bind(this));
    }
    else{
      this.marketplaceService.findXls20NftsByCollectionName(findParams).subscribe(this.handleNftResponse.bind(this));
    }
  }

  handleNftResponse(collectionResponse: ServiceResult<INftCollectionResult>) {
    this.currentStart = collectionResponse.data?.selectedFrom!;
    this.totalNfts = collectionResponse.data?.totalNfts!;
    this.offerNft = undefined;

    this.attributes = collectionResponse.data?.attributes;
    this.setupNfts(collectionResponse.data?.nfts);
  }

  setupNfts(nfts: IXls20NftDetails[] | undefined = undefined) {
    this.getPaymentInstruments().subscribe(paymentTypes => {
      this.currentPaymentInstruments = paymentTypes.data;
        this.nfts = nfts;

        if (!this.nfts) {
          return;
        }

        this.nfts.forEach(e=> {
          e.isOwnedByUser = (paymentTypes.data && paymentTypes.data!.filter(p => p.externalReference == e.ownerWalletDetails?.address).length > 0) ?? false;
        });

        this.visibleNfts = [...this.visibleNfts??[], ...this.nfts];
        this.noNfts = !this.visibleNfts || this.visibleNfts.length == 0;
        this.loadingResults = false;
        this.loaded = true;
    })
  }

  makeNewOffer(offerNft : IXls20NftDetails) {
    this.offerNft = { ...offerNft }
  }
  getNewOfferNft(){
    return this.offerNft;
  }

  setShowONlyMyNfts(onlyMine: boolean) {
    this.filterSettings.showMyNfts = onlyMine;
    this.visibleNfts = [];
    this.getNftsByCollection(1);
  }
  setCanTransferToMe(onlyMine: boolean) {
    this.filterSettings.canTransferToMe = onlyMine;
    this.visibleNfts = [];
    this.getNftsByCollection(1);
  }



  setShowTresuryNftsOnly(onlyThem: boolean) {
    this.filterSettings.showTresuryNfts = onlyThem;
    this.visibleNfts = [];
    this.getNftsByCollection(1);
  }

  setShowWithBuyOffersNfts(onlyThem: boolean) {
    this.filterSettings.hasBuyOffers = onlyThem;
    this.visibleNfts = [];
    this.getNftsByCollection(1);
  }
  setShowWithSellOffersNfts(onlyThem: boolean) {
    this.filterSettings.hasSellOffers = onlyThem;
    this.visibleNfts = [];
    this.getNftsByCollection(1);
  }

  setCombineAttributesWith(by: string) {
    this.filterSettings.combineAttributesWith = by;
    this.selectedFilterIds = this.filterSettings.selectedFilters?.map(e => e.searchId);

    this.visibleNfts = [];
    this.getNftsByCollection(1);
  }

  setSearchText(e: string) {
    this.filterSettings.searchText = e;
    this.visibleNfts = [];
    this.getNftsByCollection(1);
  }
  setFilter(e: NftFilterItem[]) {
    this.filterSettings.selectedFilters = e;
    this.selectedFilterIds = this.filterSettings.selectedFilters?.map(e => e.searchId);
    this.visibleNfts = [];
    this.getNftsByCollection(1);
  }
  setMobileSearch(e : FilterSettings) {
    this.filterSettings = e;
    this.selectedFilterIds = this.filterSettings.selectedFilters?.map(e => e.searchId);
    this.visibleNfts = [];
    this.getNftsByCollection(1);
  }
  clearSearch() {
    this.filterSettings = this.getDefaultSearch(this.attributes, this.filterSettings.isDesktop);
    this.selectedFilterIds = [];
    this.visibleNfts = [];
    this.getNftsByCollection(1);
  }

  getDefaultSearch(attributes : IBlockchainNftAttributeCollectionSummary[] | undefined = undefined, isDesktop : boolean = false) {

    return {
      selectedFilters : [],
      showMyNfts: false,
      searchText : "",
      showTresuryNfts : false,
      attributes: attributes,
      isDesktop: isDesktop,
      isLoggedIn: false,
      sortByAsc: true,
      sortByRanking: true,
      hasBuyOffers: undefined,
      hasSellOffers: undefined,
      combineAttributesWith: "and",
      canTransferToMe: false,
      sortByBids: false,
      currency: "",
    };

  }

  showFilterModal() {
    this.filterComp.toggleModal();
  }
  sortByRanking(value: boolean) {
    this.filterSettings.sortByBids = false;
    this.filterSettings.sortByRanking = value;
    this.visibleNfts = [];
    this.getNftsByCollection(1);
  }
  sortByAsc(value: boolean) {
    this.filterSettings.sortByAsc = value;
    this.visibleNfts = [];
    this.getNftsByCollection(1);
  }

  sortByBids(currency:string, value: boolean) {
    this.filterSettings.sortByRanking = false;
    this.filterSettings.currency = currency;
    this.filterSettings.sortByBids = value;
    this.visibleNfts = [];
    this.getNftsByCollection(1);
  }

  resetFilters(){
    this.sortByRanking(true);
    this.sortByBids('', false);
    this.setCanTransferToMe(false);
    this.setShowONlyMyNfts(false);
    this.setShowTresuryNftsOnly(false);
    this.setShowWithBuyOffersNfts(false);
    this.setShowWithSellOffersNfts(false);
    this.visibleNfts = [];
    this.getNftsByCollection(1);
  }

  public onScrollDown() {
    if (this.visibleNfts && this.visibleNfts.length < this.totalNfts && !this.loadingResults) {
      this.page += 1;
      this.getNftsByCollection(this.page);
    }
  }
}
