import { Injectable, OnDestroy } from '@angular/core';
import { HubConnection, HubConnectionBuilder, IHttpConnectionOptions, LogLevel } from '@microsoft/signalr';
import { Subject, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { HttpClient } from '@angular/common/http';
import { NotificationHubMessageTracking } from './hubs/notification-hub-message-tracking';
import { NotificationType } from './notifications/notification-type';

@Injectable({
  providedIn: 'root',
 })
 export class NotificationService implements OnDestroy {
  private clientGuid: string = uuidv4();
  private hubConnection: HubConnection | undefined;
  private notificationSubjects: { [action: string]: Subject<any | undefined> } = { };
  private notificationHubMessageTracking: NotificationHubMessageTracking
  private keepAlive : any;

  constructor(private httpClient: HttpClient) {
    this.notificationHubMessageTracking = new NotificationHubMessageTracking(httpClient);
   }

  ngOnDestroy(): void {
    this.disconnect();
  }

  initialize(initialSecurityToken: string | null) {
    if (initialSecurityToken) {
      this.connect(initialSecurityToken);
    } else {
      this.connect();
    }

    this.listenFor(NotificationType.SignInConfirmed).subscribe((signInResponse : any) => {
      this.connect(signInResponse.token)
    });

    this.listenFor(NotificationType.SignOut).subscribe(this.disconnect.bind(this));
  }

  listenFor<NotificationMesage>(notificationType: NotificationType) {
    let subject = this.notificationSubjects[notificationType];
    if (!subject) {
      subject = new Subject<any | undefined>();
      this.notificationSubjects[notificationType] = subject;
    }
    return subject.asObservable().pipe(map(x => x as NotificationMesage));
  }

  send<NotificationMesage>(notificationType: NotificationType, message: NotificationMesage | undefined) {
    let subject = this.notificationSubjects[notificationType];
    if (subject) {
      subject.next(message);
    }
  }

  private connect(securityToken: string = "") {
    let signalROptions = { accessTokenFactory: () => { return securityToken; } } as IHttpConnectionOptions;
    this.disconnect();

    this.hubConnection = new HubConnectionBuilder().configureLogging(LogLevel.Information).withUrl(`${environment.apiUrl}hubs/notifications`, signalROptions).withAutomaticReconnect().build();

    if (this.hubConnection) {
      this.hubConnection.start().then(() => {

        if (this.hubConnection) {

          this.keepAlive = this.notificationHubMessageTracking.setKeepAlive(this.hubConnection, this.keepAlive);

          this.hubConnection.onreconnected(error => {
            this.notificationHubMessageTracking.setupConnectionId(this.hubConnection!, this.clientGuid, environment.notiforcationsHubTypeGuid).subscribe();
          });

          this.hubConnection.on('Notification', (messageGuid, notificationType, notificationData) => {

            this.notificationHubMessageTracking.ackMessage(messageGuid).subscribe();

            let data = JSON.parse(notificationData);
            data = typeof data == "object" ? data : (JSON.parse(data.toString()) as any);

            this.send(notificationType, data)

          });

          this.notificationHubMessageTracking.setupConnectionId(this.hubConnection, this.clientGuid, environment.notiforcationsHubTypeGuid).subscribe();

        }

      });
    }
  }

  getClientGuid(): string {
    return this.clientGuid;
  }

  private disconnect() {
    this.notificationHubMessageTracking.clearKeepAlive(this.keepAlive);

    if (this.hubConnection) {

      this.hubConnection.stop();
    }
  }
 }
