import {
  Component,
  ViewChild,
  ElementRef,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';
import { IAsset } from 'src/app/shared/models/assets/asset';
import { UiService } from 'src/app/shared/services/ui.service';

@Component({
  selector: 'app-asset-listing',
  templateUrl: './asset-listing.component.html',
  styleUrls: ['./asset-listing.component.scss'],
})
export class AssetListingComponent {
  @ViewChild('container')
  container!: ElementRef;

  _asset?: IAsset = null!;

  fullNFtTokenId: string = '';
  ntTokenId: string = '';
  taxon: string = '';

  ranking: string | undefined;
  showDetail: boolean = false;

  mainMedia: string = '';
  mainMeidaType: string = '';

  thumbnailMedia: string = '';
  thumbnailType: string = '';

  displayMedia: string = '';
  displayMediaType: string = '';

  @Input()
  set asset(value: IAsset | undefined) {

    this._asset = value!;


    var self = this;
    this._asset?.assetResources.forEach(e => {

      if (e.assetResourceName == "Asset Thumbnail Optimised") {
        self.thumbnailMedia = e.assetResourceLocator!;
        self.thumbnailType = e.assetResourceType;
      } else if (e.assetResourceName == "Asset Thumbnail" && !this.thumbnailMedia) {
        self.thumbnailMedia = e.assetResourceLocator!;
        self.thumbnailType = e.assetResourceType;
      }

      if (e.assetResourceName == "Asset Resource Optimised") {
        self.mainMedia = e.assetResourceLocator!;
        self.mainMeidaType = e.assetResourceType;
      } else if (e.assetResourceName == "Asset Resource" && !this.mainMedia) {
        self.mainMedia = e.assetResourceLocator!;
        self.mainMeidaType = e.assetResourceType;
      }

      if (e.assetResourceName == "NFT Token Id") {
        self.fullNFtTokenId = e.assetResourceLocator!;
        self.ntTokenId = e.assetResourceLocator!;
      }

    })
    
    if (this.thumbnailMedia && this.thumbnailMedia != '') {
      this.displayMedia = this.thumbnailMedia;
      this.displayMediaType = this.thumbnailType;
    } else if (this.mainMedia) {
      this.displayMedia = this.mainMedia;
      this.displayMediaType = this.mainMeidaType;
    }

    if (this.displayMediaType.includes('video')) {
      this.displayMediaType = 'video'
    } else if (this.displayMediaType.includes('audio')) {
      this.displayMediaType = 'audio'
    } else {
      this.displayMediaType = 'image'
    }

    if (this.fullNFtTokenId.length > 10) {
      this.ntTokenId = this.fullNFtTokenId.substring(0, 5) + "..." + this.fullNFtTokenId.substring(this.fullNFtTokenId.length - 5)
    }

  }

  constructor(private uiService: UiService, private router: Router) {}

  signIn() {
    this.uiService.openSignInModal(false);
  }

}
