import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IAssetSeries } from 'src/app/shared/models/assets/asset-series';

@Component({
  selector: 'app-asset-series-card',
  templateUrl: './asset-series-card.component.html',
  styleUrls: ['./asset-series-card.component.scss'],
})
export class AssetSeriesCardComponent {

  @Input()
  collectionGuid?: string;

  @Input()
  assetSeries?: IAssetSeries

  @Input()
  listed: number = 0;

  @Input()
  isOwner: boolean = false;

  constructor(private router: Router) {}

  gotoSeries () {
      this.router.navigate([`/collections/${this.collectionGuid}/series/${this.assetSeries?.assetSeriesGuid}`]);
  }
  editSeries() {
    this.router.navigate([`/collections/${this.collectionGuid}/series/${this.assetSeries?.assetSeriesGuid}/edit`]);
  }
  createAsset() {
    this.router.navigate([`/collections/${this.collectionGuid}/series/${this.assetSeries?.assetSeriesGuid}/asset/create`]);
  }

}
