
<div class="collection">
  <div>
    <div class="collection__filter-toggle" [ngClass]="{'collection__filter-toggle--hide': !filterSettings.isDesktop}">
      <button type="button" (click)="isFilterVisible = !isFilterVisible">
        {{ isFilterVisible ? 'Hide filter': 'Show filter' }}
        <i class="fa-solid fa-sliders"></i>
      </button>

    </div>
    <div class="collection__filter-toggle" [ngClass]="{'collection__filter-toggle--hide': !filterSettings.isDesktop}">
      <button class="collection__filter-toggle" (click)="resetFilters()">
        Reset filter
      </button>
    </div>
    <!-- <div class="search">
      <label>
        <span class="visually-hidden">Search</span>
        <input type="text" placeholder="Search by name, rank or trait" />
      </label>

      <label>
        Sorted by

        <select>
          <option>Name</option>
        </select>
      </label>

      <div>
        <button type="button" title="grid" (click)="currentDisplayType = displayType.Grid"><i class=""></i></button>
        <button type="button" title="list" (click)="currentDisplayType = displayType.List"><i class="fa-solid fa-list"></i></button>
      </div>
    </div> -->
  </div>
  <div>
    <div [ngClass]="{'collection__hide-filter': !isFilterVisible || !filterSettings.isDesktop}">
      <app-xls-20-filter
        [filterSettings]="filterSettings"
        [attributeList]="attributes"
        [isVisible]="isFilterVisible"
        [showTraits]="hasCollectionName"
        (canTransferToMe)="setCanTransferToMe($event)"
        (showMyNftsOnly)="setShowONlyMyNfts($event)"
        (showTresuryNftsOnly)="setShowTresuryNftsOnly($event)"
        (showWithBuyOffersNfts)="setShowWithBuyOffersNfts($event)"
        (showWithSellOffersNfts)="setShowWithSellOffersNfts($event)"
        (combineAttributesWith)="setCombineAttributesWith($event)"
        (search)="setSearchText($event)"
        (filter)="setFilter($event)"
        (mobileSearch)= "setMobileSearch($event)"
        >
      </app-xls-20-filter>
    </div>

    <div>
      <div class="collection__nft-count">
        {{totalNfts}} Results
      </div>
      <div class="collection__sort-by">
        <div class="collection__sort-by-type">
          <span class="title">Sort by: </span>
          <span (click)="sortByRanking(false)" [ngClass]="{ selected : !filterSettings.sortByRanking && !filterSettings.sortByBids }">Name</span>
          <span (click)="sortByRanking(true)" [ngClass]="{ selected : filterSettings.sortByRanking && !filterSettings.sortByBids }">Ranking</span>
          <span (click)="sortByBids(filterSettings.currency, true)" [ngClass]="{ selected : filterSettings.sortByBids && !filterSettings.sortByRanking }" disabled="filterSettings.currency.trim().length === 0">Bids</span>
        </div>
        <div class="collection__sort-by-currency">
          <span class="title">Currency: </span>
          <span (click)="sortByBids('xrp', filterSettings.sortByBids)" [ngClass]="{ selected : filterSettings.currency === 'xrp' }">XRP</span>
          <span (click)="sortByBids('xSpect', filterSettings.sortByBids)" [ngClass]="{ selected : filterSettings.currency === 'xSpect' }">xSpect</span>
        </div>
        <div class="collection__sort-by-asc">
          <span class="title">Direction: </span>
          <span (click)="sortByAsc(true)"  [ngClass]="{ selected : filterSettings.sortByAsc }">a-z | 0-9</span>
          <span (click)="sortByAsc(false)"  [ngClass]="{ selected : !filterSettings.sortByAsc }">z-a | 9-0</span>
        </div>
      </div>

      <div class="collection__filter-toggle collection__filter-toggle--mobile" *ngIf="loaded && !filterSettings.isDesktop">
        <button type="button" (click)="showFilterModal()">
          Filter
          <i class="fa-solid fa-sliders"></i>
        </button>
      </div>

      <div class="collection__no-nfts" *ngIf="noNfts && loaded && !loadingResults">

        <span>No NFTs match your search and filter combination</span>
        <button class="button" type="button" (click)="clearSearch()">
          Clear search & filter
        </button>

      </div>

      <section
        class="collection__results row"
        infinite-scroll
        [infiniteScrollDistance]="scrollDistance"
        [infiniteScrollThrottle]="throttle"
        (scrolled)="onScrollDown()"
      >
        <app-xls-20-nft-listing
          [xls20Nft]="nft"
          *ngFor="let nft of visibleNfts!"
        ></app-xls-20-nft-listing>
      </section>

      <div class="collection__result-loading" *ngIf="!loaded || loadingResults">
        <app-waiting [isWaiting]="true" [fullScreen]="false"></app-waiting>
      </div>
    </div>
  </div>
</div>
