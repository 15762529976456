import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css']
})
export class CheckboxComponent {

  @Input()
  control: FormControl | undefined;

  @Input()
  label: string | undefined;

  get isChecked() {
    return !!this.control && this.control.value === true;
  }

  set isChecked(value: boolean) {
    if (this.control) {
      this.control.setValue(value);
    }
  }

  constructor() { }

}
