<div class="main-container d-flex flex-column h-100" [ngClass]="containerClasses">
  <app-header></app-header>

  <main>
    <router-outlet></router-outlet>
  </main>

  <app-footer class="mt-auto"></app-footer>
</div>

<app-alert></app-alert>

<app-sign-in></app-sign-in>
