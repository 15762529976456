import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { environment } from 'src/environments/environment';
import { Observable, of, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { NotificationHubMessageTracking } from './hubs/notification-hub-message-tracking';
import { NotificationService } from './notification.service';
import { NotificationType } from './notifications/notification-type';
import { ServiceResultStatus } from './results/service-result-status';
import { ServiceResult, mapServiceResult } from './results/service-result';
import { ISasTokenResponse } from '../models/ISasTokenResponse';
import { BlobServiceClient, AnonymousCredential  } from '@azure/storage-blob';
import { IAccountDetails, IAccountEmailSubscriptions } from '../models/security';
import { ISecurityIdentityDetails } from '../models/security/security-identity-details';
import { ISignInInstruction } from '../models/security/sign-in-instruction';
import { LaunchPadSasTokenFileTypeEnum } from '../enums/LaunchPadSasTokenFileType';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SecurityService implements OnDestroy  {

  private clientGuid: string = uuidv4();
  private accountDetails: IAccountDetails | undefined;
  private securityConnection: HubConnection | undefined;
  private failureCount = 0;
  private notificationHubMessageTracking: NotificationHubMessageTracking;
  private keepAlive : any;

  isSignedIn: boolean = false;
  securityToken: string | undefined;

  constructor(
    private httpClient: HttpClient,
    private notificationService: NotificationService,
    private router: Router,
    ) {
      this.notificationHubMessageTracking = new NotificationHubMessageTracking(httpClient);
    }

  ngOnDestroy(): void {
    this.clearConnection();
  }

   // Setup the notification hub
  initialize() {
    let securityToken = sessionStorage.getItem('security_token');

    if (!securityToken) {
      securityToken = localStorage.getItem('security_token');
    }

    if (securityToken) {
      this.isSignedIn = true;
      this.securityToken = securityToken;
    }

    this.notificationService.initialize(securityToken);
    return of();
  }

  getClientGuid() {
    return this.clientGuid;
  }

  finaliseWalletTokenLogin(providerGuid: string, actionType: number, walletAccount: string, originalMessage: string, signedMessage: any) {

    let _notificationType: NotificationType| undefined;
    let _notificationData: any;

    this.setupSecureConnection(
      (securityConnection: HubConnection) => {

        securityConnection.on('Notification', (messageGuid, notificationType, notificationData) => {

          this.notificationHubMessageTracking.ackMessage(messageGuid).subscribe();

          _notificationType = notificationType as NotificationType;
          _notificationData = notificationData

          if (notificationType === NotificationType.AddCryptoWalletComplete ||
            notificationType === NotificationType.AddCryptoWalletFailed ||
            notificationType === NotificationType.AddCryptoWalletFailedMessage)
          {
            this.finalizeRequest(_notificationType, _notificationData);
          }
        });

      },
      () => {

        var actionData = {
          walletAccount : walletAccount,
          originalMessage: originalMessage,
          signedMessage : signedMessage
        };

        var data = {
          clientId: this.clientGuid,
          account: walletAccount,
          actionMetadata : {
            actionData : {
              tenantGuid : environment.tenantGuid,
              actionType : actionType,
              data : JSON.stringify(actionData)
            }
          }

        }

        this.httpClient.post<ServiceResult>(`${environment.providerApiUrl}providers/${providerGuid}/execute`, data)
          .pipe(mapServiceResult)
          .subscribe();

      },
      () => {

      }

    );

  }

   // Sign In methods
  signIn(providerGuid: string, securityIssuerProviderGuid: string) {

    let _notificationType: NotificationType| undefined;
    let _notificationData: any;

    this.setupSecureConnection(
      (securityConnection: HubConnection) => {
        securityConnection.on('Notification', (messageGuid, notificationType, notificationData) => {

          this.notificationHubMessageTracking.ackMessage(messageGuid).subscribe();

          _notificationType = notificationType as NotificationType;
          _notificationData = notificationData

          if (notificationType === NotificationType.SignInApproved) {

            let signInResponse = JSON.parse(notificationData);
            signInResponse = typeof signInResponse == "object" ? signInResponse : (JSON.parse(signInResponse.toString()) as any);

            this.setToken(signInResponse.token);
            this.getAccountDetails(true).subscribe()

            this.finalizeRequest(_notificationType, signInResponse);
            this.finalizeSignIn(_notificationType, providerGuid, securityIssuerProviderGuid);

          }
          else if(notificationType === NotificationType.SignInRejected) {
            this.clearToken();

            this.finalizeSignIn(_notificationType, providerGuid, securityIssuerProviderGuid);
          }

        });
      },
      () => {
        this.initializeSignIn(providerGuid, securityIssuerProviderGuid);
      },
      () => {
        this.finalizeRequest(_notificationType, _notificationData);
        this.finalizeSignIn(_notificationType, providerGuid, securityIssuerProviderGuid);
      }

    );

  }

  setToken(token: string) {
    this.isSignedIn = true;
    this.securityToken = token;
    localStorage.setItem('security_token', token);
  }

  clearToken() {
    this.isSignedIn = false;
    this.securityToken = undefined;
    localStorage.removeItem('security_token');
  }

  signOut(isSecureRoute: boolean) {
    this.accountDetails = undefined;
    this.clearToken();
    this.notificationService.send(NotificationType.SignOut, undefined);

    if (isSecureRoute) {
      this.router.navigate(['/']);
    }
  }

  private setupSecureConnection(handlers: (securityConnection: HubConnection) => void, onMessageTrackingSetup: () => void, onError: () => void) {

    this.clearConnection();
    this.notificationService.send(NotificationType.SignInInitializing, undefined);

    this.securityConnection = new HubConnectionBuilder().configureLogging(LogLevel.Information).withUrl(`${environment.apiUrl}hubs/security`).withAutomaticReconnect().build();

    if (this.securityConnection) {
      this.securityConnection.start().then(() => {
        if (this.securityConnection) {

          this.securityConnection.onreconnected(error => {
            this.notificationHubMessageTracking.setupConnectionId(this.securityConnection!, this.clientGuid, environment.securityHubTypeGuid).subscribe();
          });

          handlers(this.securityConnection);

          this.notificationHubMessageTracking.setupConnectionId(this.securityConnection,this.clientGuid, environment.securityHubTypeGuid)
          .subscribe((result: any) => {
            if (result.status == ServiceResultStatus.Success) {
              onMessageTrackingSetup();
            }
          });

        }
      }).catch((error) => {
        onError();
      });
    }

  }

    // Account managgement methods
  getAccountDetails(forceReload: boolean = false) {

    if (!forceReload && this.accountDetails) {
      return of({ status: 200, data: this.accountDetails } as ServiceResult<IAccountDetails>);
    }

    return this.httpClient.get<ServiceResult<IAccountDetails>>(`${environment.apiUrl}account/current/details`)
      .pipe(mapServiceResult<IAccountDetails>)
      .pipe(tap(result => {
        if (result.status === ServiceResultStatus.Success) {
          this.accountDetails = result.data;
        }
      }));
  }

  updateAccountDetails(accountDetails: IAccountDetails) {
    return this.httpClient.post<ServiceResult>(`${environment.apiUrl}account/current/details`, accountDetails)
      .pipe(mapServiceResult)
      .pipe(tap(result => {
        if (result.status === ServiceResultStatus.Success) {
          // Todo: This call doesnt return a security details??
          this.accountDetails = accountDetails;
        }
      }));
  }

  getUserEmailSubscriptions() {
    return this.httpClient.get<ServiceResult<IAccountEmailSubscriptions>>(`${environment.apiUrl}account/GetAccountEmailSubscriptions`)
      .pipe(mapServiceResult<IAccountEmailSubscriptions>);
  }

  updateUserEmailSubscriptions(accountEmailSubscriptions: IAccountEmailSubscriptions) {
    return this.httpClient.post<ServiceResult>(`${environment.apiUrl}account/SaveAccountEmailSubscriptions`, accountEmailSubscriptions)
      .pipe(mapServiceResult);
  }

  hasXUser(accountDetails: IAccountDetails) : boolean {

    if (accountDetails.securityIdentityDetails == undefined)
    {
      return false;
    }

    return accountDetails
      .securityIdentityDetails
      .filter(e => e.providerGuid == environment.xUserProviderGuid)
      .length > 0;

  }
  hasXrplWallet(accountDetails: IAccountDetails) : boolean {

    if (accountDetails.securityIdentityDetails == undefined)
    {
      return false;
    }

    return accountDetails
      .securityIdentityDetails.filter(e => e.providerGuid.toLocaleLowerCase() == environment.xrplBlockchainProviderGuid.toLocaleLowerCase())
      .length > 0 ?? false;
  }
  hasHederaWallet(accountDetails: IAccountDetails) : boolean {

    if (accountDetails.securityIdentityDetails == undefined)
    {
      return false;
    }

    return accountDetails
      .securityIdentityDetails.filter(e => e.providerGuid == environment.hederaProviderGuid)
      .length > 0 ?? false;
  }

  getXUser(accountDetails: IAccountDetails) : ISecurityIdentityDetails | undefined {

    if (accountDetails.securityIdentityDetails == undefined)
    {
      return undefined;
    }

    return accountDetails
      .securityIdentityDetails
      .find(e => e.providerGuid == environment.xUserProviderGuid);
  }

  getXrplWallets(accountDetails: IAccountDetails) : ISecurityIdentityDetails[] | undefined {

    if (accountDetails.securityIdentityDetails == undefined)
    {
      return undefined;
    }

    return accountDetails
      .securityIdentityDetails.filter(e => e.providerGuid == environment.xrplBlockchainProviderGuid);
  }
  getHederaWallets(accountDetails: IAccountDetails) : ISecurityIdentityDetails[] | undefined {

    if (accountDetails.securityIdentityDetails == undefined)
    {
      return undefined;
    }

    return accountDetails
      .securityIdentityDetails.filter(e => e.providerGuid == environment.hederaProviderGuid);
  }

  private initializeSignIn(providerGuid: string, securityIssuerProviderGuid: string) {
    if (this.securityConnection) {

      this.keepAlive = this.notificationHubMessageTracking.setKeepAlive(this.securityConnection, this.keepAlive);

      this.httpClient.post<ServiceResult<ISignInInstruction>>(`${environment.apiUrl}security/sign-in`, { clientGuid: this.clientGuid, walletProviderGuid: providerGuid, securityIssuerProviderGuid: securityIssuerProviderGuid })
        .pipe(mapServiceResult<ISignInInstruction>)
        .subscribe(result => {
          if(result.status === ServiceResultStatus.Success) {
            this.notificationService.send(NotificationType.SignInInitialized, result.data!.data);
          }
          else {
            this.finalizeSignIn(NotificationType.SignInFailed, providerGuid, securityIssuerProviderGuid);
          }
        });
    }
  }

  private finalizeRequest(relayNotificationType: NotificationType| undefined, data: any | undefined) {

    if (relayNotificationType) {
      this.notificationService.send(relayNotificationType, data);
    }
    this.clearConnection();

  }

  private finalizeSignIn(notificationType: NotificationType| undefined,  providerGuid: string, securityIssuerProviderGuid: string) {

    if (notificationType === NotificationType.SignInFailed) {
      this.failureCount++;
      if (this.failureCount > 3) {
        this.failureCount = 0;
      } else {
        window.setTimeout(() => {
          this.signIn(providerGuid, securityIssuerProviderGuid);
        }, 2000);
      }
    }
  }

  private clearConnection() {
    this.notificationHubMessageTracking.clearKeepAlive(this.keepAlive);

    if (this.securityConnection) {
      this.securityConnection.stop();
      this.securityConnection = undefined;
    }
  }

  /**
   *
   * @param blobName Original blob name that's being uploaded.
   * @param fileType Can be 'profile', 'banner' if not one of these a guid file name is generated serverside.
   * @returns
   */
  getAzureSasToken(blobName: string, fileType: string){
    return this.httpClient.get<ServiceResult<ISasTokenResponse>>(`${environment.apiUrl}security/GetSasToken/${blobName}/${fileType}`)
      .pipe(mapServiceResult<ISasTokenResponse>);
  }

  /**
   * Uploads an image to azure storage.
   * @param file File to be uploaded
   * @param fileType Can be 'profile', 'banner' if not one of these a guid file name is generated serverside.
   * @returns
   */
  async uploadImage(file:File, fileType:string) {

    const formData: FormData = new FormData();
    formData.append("ProfileImage",file,file.name);
    formData.append("fileType", fileType);

    return await lastValueFrom(this.httpClient.post<ServiceResult<string>>(`${environment.apiUrl}account/UploadProfileImage`, formData)
      .pipe(mapServiceResult<string>)
    );
  
  }

  /**
   *
   * @param blobName Original blob name that's being uploaded.
   * @param fileType LaunchPadSasTokenFileTypeEnum with values ranging from 1 - 4
   * @returns
   */
   getSasTokenForLaunchpad(blobName:string, fileType:LaunchPadSasTokenFileTypeEnum) {
    return this.httpClient.get<ServiceResult<ISasTokenResponse>>(`${environment.apiUrl}security/GetSasTokenForLaunchpad/${blobName}/${fileType}`)
      .pipe(mapServiceResult<ISasTokenResponse>);
  }

  /**
   * Uploads an image to azure storage.
   * @param file File to be uploaded
   * @param fileType LaunchPadSasTokenFileTypeEnum with values ranging from 1 - 4
   * @returns
   */
   async uploadFileLaunchpad(file:File, fileType:LaunchPadSasTokenFileTypeEnum) {
    return new Promise<string | null>((resolve, reject) => {
      this.getSasTokenForLaunchpad(file.name, fileType).subscribe(
        async (res) => {
          if (res.status === ServiceResultStatus.Success) {
            await this.uploadAzureBlob(file,res.data! ,resolve,reject);
          }
        }
      );
    });
  }

  async uploadAzureBlob(file:File, res:ISasTokenResponse, resolve:any,reject:any){

    const blobServiceClient = new BlobServiceClient(
      `${environment.storageContainerUrl}?${res.sasToken}`,
      new AnonymousCredential()
    );

    const containerClient =
      blobServiceClient.getContainerClient(res.containerName);

    const fileName = `${res.filePath}${res.fileName}`;
    const blobClient = containerClient.getBlockBlobClient(fileName);

    try {
      const result = await blobClient.upload(file, file.size);
      resolve(blobClient.url.split("?")[0]);
    } catch (error) {
      console.error(error);
      reject(null);
    }

  }
 }
