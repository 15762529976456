import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-no-xumm-modal',
  templateUrl: './no-xumm-modal.component.html',
  styleUrls: ['./no-xumm-modal.component.scss']
})
export class NoXummModalComponent implements OnInit,AfterViewInit {
  @ViewChild('noXummModal') noXummModalRef?: ElementRef;


  hasXumm: boolean = false;
  waitingMessage: string | undefined;
  slideIndex: number = 0;
  isInteracting = false;
  
  xummInteractionQrUrl: SafeResourceUrl | undefined;
  xummInteractionQrCode: string | undefined;

  noModal: any;
  
  constructor(
    private window: Window
  ) { 
  }

  ngOnInit(): void {
    
  }
  
  ngAfterViewInit() {
    this.noModal = new this.window.bootstrap.Modal(this.noXummModalRef?.nativeElement, {
      backdrop: 'static',
      keyboard: false
    });
  }
  
  show() {
    this.noModal.show();
  }

}


