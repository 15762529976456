import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Alert } from '../models/IAlert';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  alertSubject: Subject<Alert> = new Subject<Alert>();
  resetSubject: Subject<void> = new Subject<void>();

  constructor() {}

  show(alert: Alert) {
    this.alertSubject.next(alert);
  }

  success(message: string, dismissible: boolean = true) {
    this.show({ type: 'success', message, dismissible });
  }

  info(message: string, dismissible: boolean = true) {
    this.show({ type: 'info', message, dismissible });
  }

  warning(message: string, dismissible: boolean = true) {
    this.show({ type: 'warning', message, dismissible });
  }

  error(message: string, dismissible: boolean = true) {
    this.show({ type: 'danger', message, dismissible });
  }

  reset() {
    this.resetSubject.next();
  }
}
