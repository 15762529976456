import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-waiting',
  templateUrl: './waiting.component.html',
  styleUrls: ['./waiting.component.scss']
})
export class WaitingComponent {

  @Input()
  fullScreen: boolean = true;

  @Input()
  message: string | undefined;

  @Input()
  isWaiting: boolean = false;

  constructor() { }
}
