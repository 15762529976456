import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Alert } from '../../models/IAlert';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  dismissible = true;
  alerts: Alert[] = [];
  alertSubscription?: Subscription;
  resetSubscription?: Subscription;

  constructor(private alertService: AlertService) {}

  ngOnInit(): void {
    this.alertSubscription = this.alertService.alertSubject.subscribe(
      (alert) => {
        this.alerts.push(alert);
      }
    );

    this.resetSubscription = this.alertService.resetSubject.subscribe(() => {
      this.reset();
    });
  }

  reset(): void {
    this.alerts = [];
  }

  onClosed(dismissedAlert: any): void {
    this.alerts = this.alerts.filter((alert) => alert !== dismissedAlert);
  }
}
