<div #modal class="modal fade" id="signInModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body modal-body--no-margin">
        <i class="fa-solid fa-xmark" (click)="clearSignIn()"></i>
        <div>
          <app-slide [slideIndex]="slideIndex" [slideCount]="5" class="app-slide">
            <div first-slide class="signin-qr">
              <span *ngIf="signInQrCode">
                Sign In to xSpectar.com with XUMM
              </span>

              <app-scan-qr-code
                [signInQrUrl]="signInQrUrl"
                [signInQrCode]="signInQrCode"
                (cancel)="cancelSignIn()"
              ></app-scan-qr-code>

              <p class="or-para">OR</p>

              <p class="footer-para">Have an account? <a (click)="switchSlide(2)">Login</a></p>
            </div>
            <div second-slide class="first-sign-in">
              <div class="text-center">
                <h2>
                  Welcome to xSpectar
                </h2>
                <p>
                  Take a moment to update your preferences so you can get the most our of the xSpectar experience
                </p>
              </div>
              <app-security-details *ngIf="showDetails" [isFirstSignIn]="true" (skipped)="clearSignIn()" (updated)="clearSignIn()"></app-security-details>
            </div>

            <div third-slide>
              <form [formGroup]="loginGroup" (submit)="login()">
                <div class="text-center">
                  <h2 class="head head--small">
                    Login
                  </h2>
                </div>
                <div>
                  <label>
                    <span class="visually-hidden">Email</span>
                    <input type="text" name="email" formControlName="email" placeholder="email"/>
                    <div *ngIf="formSubmitted && loginGroup.get('email')?.invalid" class="form-error">
                      <div *ngIf="loginGroup.get('email')?.errors?.['required']">
                        Please enter an email.
                      </div>
                    </div>
                    <div *ngIf="formSubmitted && loginGroup.get('email')?.invalid" class="form-error">
                      <div *ngIf="loginGroup.get('email')?.errors?.['email']">
                        Please enter a valid email.
                      </div>
                    </div>
                  </label>
                </div>
                <div>
                  <label>
                    <span class="visually-hidden">Password</span>
                    <input type="password" name="password" formControlName="password" placeholder="password" />
                    <div *ngIf="formSubmitted && loginGroup.get('password')?.invalid" class="form-error">
                      <div *ngIf="loginGroup.get('password')?.errors?.['required']">
                        Please enter your password.
                      </div>
                    </div>
                  </label>
                </div>

                <div *ngIf="formSubmitted && formError" class="form-error">
                  <div>
                    Email and/or Password is incorrect
                  </div>
                </div>

                <div class="forgot-password">
                  <a (click)="switchSlide(4)" class="link">Forgot password?</a>
                </div>

                <button type="submit" class="button button--primary" [ngClass]="{'button--disabled': !loginGroup.valid}">Login</button>
              </form>

              <p class="or-para">OR</p>

              <button type="button" (click)="switchSlide(0)" class="button button--tertairy-alt">Connect your wallet</button>

              <p class="footer-para">Don't have an account? <a (click)="switchSlide(3)" class="link">Sign up</a></p>
            </div>

            <div fourth-slide>
              <app-register (responseEmitter)="registerSuccess($event)"></app-register>

              <p class="or-para">OR</p>

              <button type="button" class="button button--primary" (click)="switchSlide(0)" class="button button--tertairy-alt">Connect your wallet</button>

              <p class="footer-para">Have an account? <a (click)="switchSlide(2)" class="link">Log in</a></p>
            </div>

            <div fifth-slide>
              <form [formGroup]="forgotPasswordGroup" (submit)="forgotPassword()" *ngIf="!forgotPasswordSuccess">
                <div class="text-center">
                  <h2 class="head head--small">
                    Forgot password
                  </h2>
                </div>
                <div>
                  <label>
                    <span class="visually-hidden">Email</span>
                    <input type="text" name="email" formControlName="email" placeholder="email"/>
                    <div *ngIf="formSubmitted && forgotPasswordGroup.get('email')?.invalid" class="form-error">
                      <div *ngIf="forgotPasswordGroup.get('email')?.errors?.['required']">
                        Please enter an email.
                      </div>
                    </div>
                    <div *ngIf="formSubmitted && forgotPasswordGroup.get('email')?.invalid" class="form-error">
                      <div *ngIf="forgotPasswordGroup.get('email')?.errors?.['email']">
                        Please enter a valid email.
                      </div>
                    </div>
                  </label>
                </div>

                <div *ngIf="formSubmitted && formError && formErrorMessage" class="form-error">
                  <div>
                    {{ formErrorMessage }}
                  </div>
                </div>

                <button type="submit" class="button button--primary" [ngClass]="{'button--disabled': !forgotPasswordGroup.valid}">Submit</button>
              </form>

              <p *ngIf="forgotPasswordSuccess">Reset password link sent</p>

              <p class="or-para">OR</p>

              <button type="button" (click)="switchSlide(0)" class="button button--tertairy-alt">Connect your wallet</button>

              <p class="footer-para">Have an account? <a (click)="switchSlide(2)" class="link">Log in</a></p>
            </div>
          </app-slide>

          <app-waiting [isWaiting]="!!waitingMessage" [message]="waitingMessage"></app-waiting>
        </div>
      </div>
  </div>
</div>
