export enum NotificationType {
  SignInApproved = 'signin-approved',
  SignInRejected = 'signin-rejected',

  PurchaseAwaitingConfirmation = 'purchase-awaiting',
  PurchaseFailed = 'purchase-failed',
  PurchaseCompleted = 'purchase-completed',
  PurchaseProcessing = 'purchase-processing',

  PaymentComplete = 'payment-complete',
  PaymentFailed = 'payment-failed',
  PaymentInstrumentAdded = 'payment-instrument-added',
  PaymentInstrumentFailed = "payment-instrument-failed",

  AcceptOfferAwaitingConfirmation = 'accept-offer-awaiting',
  AcceptOfferComplete = 'accept-offer-complete',
  AcceptOfferFailed = 'accept-offer-failed',

  AddCryptoWalletComplete = "add-crypto-wallet-complete",
  AddCryptoWalletFailed = "add-crypto-wallet-failed",
  AddCryptoWalletFailedMessage = "add-crypto-wallet-failed-message",

  AcceptBrokeredOfferAwaitingConfirmation = 'accept-brokered-offer-awaiting',
  AcceptBrokeredOfferComplete = 'accept-brokered-offer-complete',
  AcceptBrokeredOfferFailed = 'accept-brokered-offer-failed',

  CreateOfferComplete = 'create-offer-complete',
  CreateOfferFailed = 'create-offer-failed',

  CancelOfferComplete = 'cancel-offer-complete',
  CancelOfferFailed = 'cancel-offer-failed',

  SignInInitializing = 'signin-initializing',
  SignInInitialized = 'signin-initialized',
  SignInConfirmed = 'signin-confirmed',
  SignInCancelled = 'signin-cancelled',
  SignInFailed = 'signin-failed',
  SignOut = 'signout',

  XrplOffersUpdate = 'xrpl-offers-update',

  XrplNftOwnerUpdate = "xrpl-nft-updated-owners",
  
  MintSuccessful = "mint-nft-successful",
  MintFailed = "mint-nft-failed"
}
