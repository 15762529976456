import { Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UrlService } from '../shared/services/url.service';
import { UiService } from '../shared/services/ui.service';
import { SecurityService } from '../shared/services/security.service';
import { NotificationService } from '../shared/services/notification.service';
import { NotificationType } from '../shared/services/notifications/notification-type';
import { ServiceResultStatus } from '../shared/services/results/service-result-status';
import { ServiceResult } from '../shared/services/results/service-result';
import { environment } from 'src/environments/environment';
import { AccountService } from '../shared/services/account.service';
import { IProfile } from '../shared/models/IProfile';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth-guard';
import { IAccountDetails } from '../shared/models/security';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  private clickOutside = new EventEmitter<void>();

  details?: IAccountDetails;
  showAccountDetails = false;
  navigationOpen = false;
  subscriptions: Subscription[] = [];
  profileInfo?: IProfile;
  aboutUsUrl = `${environment.marketingSite}xspectar-marketplace.html`;

  get userName() {
    return this.details?.accountUsername;
  }

  get userEmail() {
    return this.details?.emailAddress;
  }

  get isSignedIn() {
    return this.securityService.isSignedIn;
  }

  constructor(
    private securityService: SecurityService,
    private elementRef: ElementRef,
    private notificationService: NotificationService,
    public urlService: UrlService,
    private uiService: UiService,
    private accountService: AccountService,
    private activatedRoute: ActivatedRoute,
    private router: Router
      ) { }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const clickedElement = event.target as HTMLElement;
    const isClickedInside = this.elementRef.nativeElement.contains(clickedElement);

    if (!isClickedInside) {
      this.showAccountDetails = false;
    }
  }

  ngOnInit(): void {
    this.loadDetails();

    this.subscriptions.push(this.notificationService.listenFor(NotificationType.SignOut).subscribe(() => {
      this.details = undefined;
    }));

    this.subscriptions.push(this.notificationService.listenFor(NotificationType.SignInConfirmed).subscribe(() => {
      this.loadDetails();
    }));

    this.subscriptions.push(this.notificationService.listenFor(NotificationType.SignInApproved).subscribe(() => {
      this.loadDetails();
    }));
  }

  signOut() {
      const routeConfig = this.activatedRoute.snapshot.routeConfig;
      this.securityService.signOut(routeConfig?.canActivate?.includes(AuthGuard) as boolean);
  }

  signIn() {
    this.uiService.openSignInModal();
  }

  loadDetails() {
    this.securityService.getAccountDetails().subscribe((result: ServiceResult<IAccountDetails>) => {
      if (result.status === ServiceResultStatus.Success) {
        this.details = result.data;
      }
    });

    this.accountService.getLoggedInUserProfile()
      .subscribe(result => {
        if (result.status === ServiceResultStatus.Success) {
          this.profileInfo = result.data;
        }
      });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }
}
