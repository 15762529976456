import { Component, Input, OnInit } from '@angular/core';
import { MenuServiceMenuItem, MenuServiceMenuItemCollection } from '../../models/menu-service-menu-item';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {
  @Input() menuItemCollection?: MenuServiceMenuItemCollection;

  constructor() { }
}
