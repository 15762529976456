import { Component, Input, OnInit } from '@angular/core';
import { ViewType } from '../../enums/view-type';

@Component({
  selector: 'app-favourite',
  templateUrl: './favourite.component.html',
  styleUrls: ['./favourite.component.scss']
})
export class FavouriteComponent implements OnInit {
  @Input()
  id?: string

  @Input()
  isFavourite?: boolean

  @Input()
  type?: ViewType

  constructor() { }

  ngOnInit(): void {
  }

  toggleFavourite() {
    this.isFavourite = !this.isFavourite;
  }
}
