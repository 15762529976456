import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IBlockchainNftOfferDetails } from '../../models/block-chain-nfts/IBlockchainNftOfferDetails';
import { SecurityService } from 'src/app/shared/services/security.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IPaymentInstrument } from '../../models/account';
import { MarketplaceService } from 'src/app/shared/services/marketplace.service';
import { NftCancelOfferProgressStatus } from '../../models/marketplace/nft-cancel-offer';
import { NoXummModalComponent } from '../no-xumm-modal/no-xumm-modal.component';
import { NftAcceptOfferProgressStatus } from '../../models/marketplace';
import { NftAcceptBrokeredOfferProgressStatus } from '../../models/marketplace/nft-accept-brokered-offer';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nft-offer-cancel-modal',
  templateUrl: './nft-offer-cancel-modal.component.html',
  styleUrls: ['./nft-offer-cancel-modal.component.scss']
})
export class NftOfferCancelModalComponent implements OnInit,AfterViewInit {
  @ViewChild('appCancelOfferModal') cancelOfferModalRef?: ElementRef;
  @ViewChild(NoXummModalComponent) noXummModalComponent: NoXummModalComponent | undefined;


  hasXumm: boolean = false;
  waitingMessage: string | undefined;
  slideIndex: number = 0;
  isInteracting = false;
  
  xummInteractionQrUrl: SafeResourceUrl | undefined;
  xummInteractionQrCode: string | undefined;

  cancelOfferModal: any;
  
  currentPaymentInstruments : IPaymentInstrument[] | undefined;

 
  @Input()
  set HasXumm(value: boolean) {
    this.hasXumm = value;
  }

  @Input()
  set CurrentPaymentInstruments(value: IPaymentInstrument[] | undefined) {
    this.currentPaymentInstruments = value;
  }

  
  @Output()
  offerCanceled: EventEmitter<IBlockchainNftOfferDetails> = new EventEmitter<IBlockchainNftOfferDetails>();
  
  @Output()
  offerAccepted: EventEmitter<IBlockchainNftOfferDetails> = new EventEmitter<IBlockchainNftOfferDetails>();

  constructor(
    private marketplaceService: MarketplaceService,
    private securityService: SecurityService,
    private window: Window,
    private sanitizer: DomSanitizer,
  ) { 
  }

  ngOnInit(): void {
    
  }
  
  ngAfterViewInit() {
    this.cancelOfferModal = new this.window.bootstrap.Modal(this.cancelOfferModalRef?.nativeElement, {
      backdrop: 'static',
      keyboard: false
    });
  }
  
  cancelOffer(offer: IBlockchainNftOfferDetails) {
    if (!this.securityService.isSignedIn) {
      return;
    }
    
    if (!this.hasXumm) {
      this.noXummModalComponent?.show();
      return;
    }

    this.waitingMessage = 'CREATING QR CODE';
    this.slideIndex = 1;
    this.isInteracting = true;
    this.cancelOfferModal.show();

    this.securityService.getAccountDetails().subscribe(accountDetailsResponse => {

      if (accountDetailsResponse.data == null) {
        this.cancelXummInteraction();
        console.log("you need to be logged in to use the marketplace");
        return;
      }

      let account = accountDetailsResponse.data;
      let paymentInstrument = this.currentPaymentInstruments;
      this.marketplaceService.cancelXls20NftOffer(offer.blockchainOfferLedgerId, offer.nftName as string, account.accountGuid as string, paymentInstrument![0].paymentInstrumentGuid).subscribe(notificationResult => {

        if (notificationResult.immediateResponse) {
          this.xummInteractionQrCode = notificationResult.immediateResponse.data.qrCodeImage;
          this.xummInteractionQrUrl = this.sanitizer.bypassSecurityTrustResourceUrl(notificationResult.immediateResponse.data.qrCodeUrl);
          this.waitingMessage = undefined;
          this.slideIndex = 0;

          let currentProgress = 0;
          notificationResult.subscribe(result => {
            this.waitingMessage = 'Please Wait';

            switch(result.progressStatus) {
              case NftCancelOfferProgressStatus.Complete:

                if (currentProgress <= 1) {
                  this.waitingMessage = 'NFT OFFER CANCELLED!';
                  this.slideIndex = 1;

                  setTimeout(() => {
                    this.offerCanceled.emit(offer);
                    this.cancelXummInteraction();
                  }, 2000)

                  currentProgress = 2;
                }
                break;
              case NftCancelOfferProgressStatus.Failed:
                this.waitingMessage = `CANCEL OFFER FAILED`;
                this.slideIndex = 1;

                setTimeout(() => {
                  this.cancelXummInteraction();
                }, 2000)

                break;
            }
          });

        }

      });

    })

  }


  acceptOffer(offer: IBlockchainNftOfferDetails) {
    if (!this.securityService.isSignedIn) {
      return;
    }

    if (!this.hasXumm) {
      this.noXummModalComponent?.show();
      return;
    }

    this.waitingMessage = 'CREATING QR CODE';
    this.slideIndex = 1;
    this.isInteracting = true;
    this.cancelOfferModal.show();

    this.securityService.getAccountDetails().subscribe(accountDetailsResponse => {

      if (accountDetailsResponse.data == null) {
        this.cancelXummInteraction();
        console.log("you need to be logged in to use the marketplace");
        return;
      }

      let account = accountDetailsResponse.data!;
   
      let paymentInstrument = this.currentPaymentInstruments?.filter(e => e.PaymentInstrumentBlockchainProviderGuid = environment.xrplBlockchainProviderGuid);

        if (!paymentInstrument || paymentInstrument?.length == 0) {
          this.cancelXummInteraction();
          console.log("Could not find wallet in your account for the XRPL network");
          return;
        }

        let nftName = offer.nftName ?? offer.nftTokenId.substring(offer.nftTokenId.length - 10);

        if (offer.amount == 0) {

          this.marketplaceService.acceptXls20NftOffer(
            offer,
            offer.nftTokenId,
            nftName as string,
            account.accountGuid ?? "",
            paymentInstrument[0].paymentInstrumentGuid
          ).subscribe(notificationResult => {

            if (notificationResult.immediateResponse) {
              this.xummInteractionQrCode = notificationResult.immediateResponse.data.qrCodeImage;
              this.xummInteractionQrUrl = this.sanitizer.bypassSecurityTrustResourceUrl(notificationResult.immediateResponse.data.qrCodeUrl);
              this.waitingMessage = undefined;
              this.slideIndex = 0;

              let currentProgress = 0;
              notificationResult.subscribe(result => {
                this.waitingMessage = 'Please Wait';

                switch(result.progressStatus) {
                  case NftAcceptOfferProgressStatus.AwaitingConfirmation:
                    if (currentProgress <= 0){
                      this.waitingMessage = 'AWAITING CONFIRMATION';
                      currentProgress = 1;
                      this.slideIndex = 1;
                    }
                    break;
                  case NftAcceptOfferProgressStatus.Complete:

                    if (currentProgress <= 1) {
                      this.waitingMessage = 'NFT OFFER CREATED!';
                      this.offerAccepted.emit(offer);
                      this.slideIndex = 1;

                      setTimeout(() => {
                        this.cancelXummInteraction();
                      }, 2000)

                      currentProgress = 2;
                    }
                    break;
                  case NftAcceptOfferProgressStatus.Failed:
                    this.waitingMessage = `NFT OFFER FAILED`;
                    this.slideIndex = 1;

                    setTimeout(() => {
                      this.cancelXummInteraction();
                    }, 2000)

                    break;
                }
              });

            }

          });

        } else {
          this.marketplaceService.acceptBrokeredXls20NftOffer(
            offer,
            offer.nftTokenId,
            nftName as string,
            account.accountGuid ?? "",
            paymentInstrument[0].paymentInstrumentGuid,
            offer.OwnerWalletDetails?.address!
          ).subscribe(notificationResult => {

            if (notificationResult.immediateResponse) {
              this.xummInteractionQrCode = notificationResult.immediateResponse.data.qrCodeImage;
              this.xummInteractionQrUrl = this.sanitizer.bypassSecurityTrustResourceUrl(notificationResult.immediateResponse.data.qrCodeUrl);
              this.waitingMessage = undefined;
              this.slideIndex = 0;

              let currentProgress = 0;
              notificationResult.subscribe(result => {
                this.waitingMessage = 'Please Wait';

                switch(result.progressStatus) {
                  case NftAcceptBrokeredOfferProgressStatus.AwaitingConfirmation:
                    if (currentProgress <= 0){
                      this.waitingMessage = 'AWAITING CONFIRMATION';
                      currentProgress = 1;
                      this.slideIndex = 1;
                    }
                    break;
                  case NftAcceptBrokeredOfferProgressStatus.Complete:

                    if (currentProgress <= 1) {
                      this.waitingMessage = 'NFT OFFER CREATED!';
                      this.offerAccepted.emit(offer);
                      this.slideIndex = 1;

                      setTimeout(() => {
                        this.cancelXummInteraction();
                      }, 2000)

                      currentProgress = 2;
                    }
                    break;
                  case NftAcceptBrokeredOfferProgressStatus.Failed:
                    this.waitingMessage = `NFT OFFER FAILED`;
                    this.slideIndex = 1;

                    setTimeout(() => {
                      this.cancelXummInteraction();
                    }, 2000)

                    break;
                }
              });

            }

          });
        }

    })

  }


  cancelXummInteraction() {
    this.isInteracting = false;
    this.waitingMessage = undefined;
    this.slideIndex = 0;
    this.cancelOfferModal.hide();
  }

}


