import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, tap, map, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NotificationType } from './notifications/notification-type';
import { NotificationService } from './notification.service';
import { ServiceResult, mapServiceResult } from './results/service-result';
import { ServiceResultStatus } from './results/service-result-status';
import { IAsset } from '../models/assets/asset';
import { ICurrencyAmount } from '../models/marketplace';
import { IVaultAssetMetadata } from '../models/assets/asset-types/vault-asset-metadata';
import { IProfile } from '../models/IProfile';
import { IBlockchainNftCollectionSummary } from '../models/IBlockchainNftCollectionSummary';
import { IAccountTransaction, IPaymentInstrument, IAccountTransactionData } from '../models/account';
import { IBlockchainNftOfferDetails } from '../models/block-chain-nfts/IBlockchainNftOfferDetails';
import { ICollectionProfileSummary } from '../models/ICollectionProfileSummary';
import { ICreateXUserRequest } from '../models/ICreateXUserRequest';
import { SecurityService } from './security.service';
import { ILoginXUserRequest } from '../models/ILoginXUserRequest';
import { IForgottonPasswordXUserRequest } from '../models/IForgottonPasswordXUserRequest';
import { IForgotPasswordXUserResponse } from '../models/IForgotPasswordXUserResponse';
import { IResetPasswordXUserRequest } from '../models/IResetPasswordXUserRequest';
import { ISignInInstruction } from '../models/security/sign-in-instruction';
import { IPagedProfile } from '../models/profile/IPagedProfile';
import { IProfileRequest } from '../models/profile/IProfileRequest';
import { IProfileUpdateInfo } from '../models/IProfileUpdateInfo';
import { IOwnedCollection } from '../models/account/IOwnedCollection';
import { IAssetCollection } from '../models/assets/asset-collection';
import { ICreateOrUpdateAssetCollectionRequest } from '../models/ICreateOrUpdateAssetCollectionRequest';
import { IPostProfileData } from '../models/IPostProfileData';
import { ICreateOrUpdateAssetSeriesRequest } from '../models/ICreateOrUpdateAssetSeriesRequest';
import { ICreateOrUpdateAssetSeriesResponse } from '../models/assets/ICreateOrUpdateAssetSeriesResponse';
import { ICreateOrUpdateAssetRequest } from '../models/ICreateOrUpdateAssetRequest';
import { ICreateOrUpdateAssetResponse } from '../models/ICreateOrUpdateAssetResponse';
import { IFinaliseSignInResult } from '../models/security/IFinaliseSignInResult';

@Injectable({
  providedIn: 'root',
 })
 export class AccountService {
  private accountTransactions: IAccountTransaction[] | undefined;
  private accountAssets: IAsset[] | undefined;
  private accountPaymentTypes: IPaymentInstrument[] | undefined;

  loggedIn: boolean = false;

  accountGuid: string = "";
  canBuyAgentNfts: boolean = false;
  canBuy444AgentNfts: boolean = false  ;
  agentsOwned = 0;
  amountInVault = 0;
  xrpBalance: ICurrencyAmount = { value: 0, currencyGuid: environment.xrpCurrencyGuid };
  xSpectarBalance: ICurrencyAmount = { value: 0, currencyGuid: environment.xSpectarCurrencyGuid };

  constructor(
    private readonly httpClient: HttpClient,
    private securityService: SecurityService,
    readonly notificationService: NotificationService) {
      notificationService.listenFor(NotificationType.SignOut).subscribe(() => {
        this.accountAssets?.splice(0);
        this.accountTransactions?.splice(0);
        this.canBuyAgentNfts = false;
        this.canBuy444AgentNfts = false;
        this.agentsOwned = 0;
        this.amountInVault = 0;
        this.loggedIn = false;
      });

      notificationService.listenFor(NotificationType.SignInConfirmed).subscribe(() => {

        this.getAccountAssets(true).subscribe(() => {
          this.loggedIn = true;
        });
      });
  }

  getAccountAssets(forceReload: boolean = false) {

    if (this.accountAssets && !forceReload) {
      return of({ status: 200, data: this.accountAssets } as ServiceResult<IAsset[]>);
    }

    return this.httpClient.get<ServiceResult<IAsset[]>>(`${environment.apiUrl}account/current/assets`)
      .pipe(mapServiceResult<IAsset[]>)
      .pipe(tap(result => {
        if (result.status === ServiceResultStatus.Success && result.data) {

          result.data.forEach(x => {

            x.assetMetadata = JSON.parse(x.assetMetadata as any as string);

            if (x.assetSeries.assetSeriesGuid == environment.agentAssetSeriesGuid){
              (x.assetMetadata as any).agentImage = 'https://xspectarnfts.blob.core.windows.net/nfts-small/' + x.assetGuid + '.jpg';
              (x.assetMetadata as any).agentName = x.assetName;
            } else if (x.assetSeries.assetSeriesGuid == environment.the88agentAssetSeriesGuid) {
              (x.assetMetadata as any).agentImage = x.assetResources[0].assetResourceLocator;
              (x.assetMetadata as any).agentName = x.assetName;
            }

          });

          this.accountAssets = result.data;
          this.canBuyAgentNfts = result.data.some(x => x.assetSeries.assetSeriesGuid === environment.unlockAssetSeriesGuid);
          this.canBuy444AgentNfts  = result.data.some(x => x.assetSeries.assetSeriesGuid === environment.the88agentAssetSeriesGuid);
          this.agentsOwned = result.data.filter(x => x.assetSeries.assetSeriesGuid === environment.agentAssetSeriesGuid).length;
          this.amountInVault = this.calculateTotalVaultAmount(result.data);

        }
      }));
  }

  getAccountTransactions() {
    if (this.accountTransactions) {
      return of({ status: 200, data: this.accountTransactions } as ServiceResult<IAccountTransaction[]>);
    }

    return this.httpClient.get<ServiceResult<IAccountTransaction[]>>(`${environment.apiUrl}account/current/transactions`)
      .pipe(mapServiceResult<IAccountTransaction[]>)
      .pipe(tap(result => {
        if (result.status === ServiceResultStatus.Success && result.data) {
          this.accountTransactions = result.data;
        }
      }));
  }

  getAccountTransactionDetail(transaction: IAccountTransaction) {
    if (transaction.data) {
      return of({ status: 200, data: transaction.data } as ServiceResult<IAccountTransactionData[]>);
    }

    return this.httpClient.get<ServiceResult<IAccountTransaction>>(`${environment.apiUrl}account/current/transactions/${transaction.accountTransactionGuid}`)
      .pipe(mapServiceResult<IAccountTransaction>)
      .pipe(tap(result => {
        if (result.status === ServiceResultStatus.Success && result.data) {
          transaction.data = result.data.data;
        }
      }))
      .pipe(map(x => ({ status: 200, data: x.data!.data } as ServiceResult<IAccountTransactionData[]>)));
  }

  getAccountPaymentInstruments(forceReload : boolean = false) {

    if (this.accountPaymentTypes && !forceReload) {
      return of({ status: 200, data: this.accountPaymentTypes } as ServiceResult<IPaymentInstrument[]>);
    }

    return this.httpClient.get<ServiceResult<IPaymentInstrument[]>>(`${environment.apiUrl}payment/paymentInstruments/current`)
      .pipe(mapServiceResult<IPaymentInstrument[]>)
      .pipe(tap(result => {
        if (result.status === ServiceResultStatus.Success && result.data) {
           result.data.forEach(e => this.accountPaymentTypes?.push(e));
      }}));

  }

  register(createXUserRequest: ICreateXUserRequest) {
    return this.httpClient.post<ServiceResult<IFinaliseSignInResult>>(`${environment.apiUrl}account/register`, createXUserRequest)
      .pipe(mapServiceResult<IFinaliseSignInResult>)
      .pipe(tap((result: ServiceResult<IFinaliseSignInResult>) => {
        if (result.status === ServiceResultStatus.Success) {
          this.securityService.setToken(result.data?.token.accessToken!);
          this.securityService.getAccountDetails(true).subscribe();
        }
      }));
  }

  login(loginXUserRequest: ILoginXUserRequest) {
    return this.httpClient.post<ServiceResult<IFinaliseSignInResult>>(`${environment.apiUrl}security/finalise-sign-in`, loginXUserRequest)
      .pipe(mapServiceResult<IFinaliseSignInResult>)
      .pipe(tap((result: ServiceResult<IFinaliseSignInResult>) => {
        if (result.status === ServiceResultStatus.Success) {
          this.securityService.setToken(result.data?.token.accessToken!);
          this.securityService.getAccountDetails(true).subscribe();
        }
      }));
  }

  forgotPassword(forgottonPasswordXUserRequest: IForgottonPasswordXUserRequest) {
    return this.httpClient.post<ServiceResult<IForgotPasswordXUserResponse>>(`${environment.apiUrl}account/forgotPassword`, forgottonPasswordXUserRequest)
      .pipe(mapServiceResult<any>);
  }

  checkResetPassword(guid: any) {
    return this.httpClient.get<ServiceResult<boolean>>(`${environment.apiUrl}account/${guid}/reset`)
    .pipe(mapServiceResult<boolean>);
  }

  resetPassword(guid: any, resetPasswordXUserRequest: IResetPasswordXUserRequest) {
    return this.httpClient.post<ServiceResult<boolean>>(`${environment.apiUrl}account/${guid}/reset`, resetPasswordXUserRequest)
      .pipe(mapServiceResult<boolean>);
  }

  addXuserToAccount(createXUserRequest: ICreateXUserRequest) {
    return this.httpClient.post<ServiceResult<any>>(`${environment.apiUrl}account/addXuser`, createXUserRequest)
      .pipe(mapServiceResult<any>);
  }

  removeCrytoWallet(securityGuid: string) {

    return this.httpClient.get<ServiceResult<any>>(`${environment.apiUrl}account/current/RemoveCryptoWallet/${securityGuid}`)
      .pipe(mapServiceResult<any>);


  }

  InitializeAddCryptoWallet(clientGuid: string, providerGuid: string) {
    return this.httpClient.post<ServiceResult<ISignInInstruction>>(`${environment.apiUrl}account/InitializeAddCryptoWallet`, {
        clientGuid,
        providerGuid
      })
      .pipe(mapServiceResult<ISignInInstruction>);
  }

  getLoggedInUserProfile() {
    return this.httpClient.get<ServiceResult<IProfile>>(`${environment.apiUrl}account/GetLoggedInUserProfile`)
      .pipe(mapServiceResult<IProfile>);
  }

  getAccountProfile(profileGuid: string) {
    return this.httpClient.get<ServiceResult<IProfile>>(`${environment.apiUrl}account/GetAccountProfile/${profileGuid}`)
      .pipe(mapServiceResult<IProfile>);
  }

  CreateOrUpdateAccountProfile(postProfileData: IPostProfileData) {

    let asForData: FormData = new FormData();

    if (postProfileData.bannerImage != null){
      asForData.append('postProfileData.bannerImage', postProfileData.bannerImage, postProfileData.bannerImage?.name);
    }

    if (postProfileData.profilePicture != null){
      asForData.append('postProfileData.profilePicture', postProfileData.profilePicture, postProfileData.profilePicture?.name);
    }

    asForData.append('postProfileData.bannerImageUri', postProfileData.bannerImageUri);
    asForData.append('postProfileData.profilePictureUri', postProfileData.profilePictureUri);
    asForData.append('postProfileData.displayName', postProfileData.displayName);
    asForData.append('postProfileData.description', postProfileData.description);
    asForData.append('postProfileData.telegramHandle', postProfileData.telegramHandle);
    asForData.append('postProfileData.discordServer', postProfileData.discordServer);
    asForData.append('postProfileData.twitterHandle', postProfileData.twitterHandle);
    asForData.append('postProfileData.profileGuid', postProfileData.profileGuid!);
    
    return this.httpClient.post<ServiceResult<IProfile>>(`${environment.apiUrl}account/CreateOrUpdateAccountProfile`, asForData)
      .pipe(mapServiceResult<IProfile>);

  }

  getProfileList(profileRequest: IProfileRequest) {
    return this.httpClient.post<ServiceResult<IPagedProfile>>(`${environment.apiUrl}account/${profileRequest.providerGuid}/GetProfileList`, profileRequest)
      .pipe(mapServiceResult<IPagedProfile>);
  }

  getOwnedNftsByCollection(providerGuid: string, profileGuid: string, collectionName: string) {
    return this.httpClient.get<ServiceResult<IBlockchainNftCollectionSummary[]>>(`${environment.apiUrl}account/${providerGuid}/GetOwnedNftsByCollection/${profileGuid}/${collectionName}`)
      .pipe(mapServiceResult<IBlockchainNftCollectionSummary[]>);
  }

  getCollectionsWithOwnedNfts(providerGuid: string, profileGuid: string) {
    return this.httpClient.get<ServiceResult<IBlockchainNftCollectionSummary[]>>(`${environment.apiUrl}account/${providerGuid}/GetCollectionsWithOwnedNfts/${profileGuid}`)
      .pipe(mapServiceResult<IBlockchainNftCollectionSummary[]>);
  }

  getCollections() {
    return this.httpClient.get<ServiceResult<IAssetCollection[]>>(`${environment.apiUrl}account/getCollections`)
      .pipe(mapServiceResult<IAssetCollection[]>);
  }

   getNftsByOffersCreated(providerGuid: string, profileGuid: string) {
    return this.httpClient.get<ServiceResult<IBlockchainNftOfferDetails[]>>(`${environment.apiUrl}account/${providerGuid}/GetNftsByOffersCreated/${profileGuid}`)
      .pipe(mapServiceResult<IBlockchainNftOfferDetails[]>);
  }

  getNftsByOffersReceived(providerGuid: string, profileGuid: string) {
    return this.httpClient.get<ServiceResult<IBlockchainNftOfferDetails[]>>(`${environment.apiUrl}account/${providerGuid}/GetNftsByOffersReceived/${profileGuid}`)
      .pipe(mapServiceResult<IBlockchainNftOfferDetails[]>);
  }

  getNftsByTransfers(providerGuid: string, profileGuid: string) {
    return this.httpClient.get<ServiceResult<IBlockchainNftOfferDetails[]>>(`${environment.apiUrl}account/${providerGuid}/GetNftsByTransfers/${profileGuid}`)
      .pipe(mapServiceResult<IBlockchainNftOfferDetails[]>);
  }

  CreateOrUpdateAssetCollectionWithProfile(collectionData: ICreateOrUpdateAssetCollectionRequest) {

    let asForData: FormData = new FormData();

    if (collectionData.profileData.bannerImage != null){
      asForData.append('postProfileData.bannerImage', collectionData.profileData.bannerImage, collectionData.profileData.bannerImage?.name);
    }

    if (collectionData.profileData.profilePicture != null){
      asForData.append('postProfileData.profilePicture', collectionData.profileData.profilePicture, collectionData.profileData.profilePicture?.name);
    }

    asForData.append('postProfileData.profilePictureUri', collectionData.profileData.profilePictureUri);
    asForData.append('postProfileData.bannerImageUri', collectionData.profileData.bannerImageUri);
    
    asForData.append('postProfileData.displayName', collectionData.profileData.displayName);
    asForData.append('postProfileData.description', collectionData.profileData.description);
    asForData.append('postProfileData.telegramHandle', collectionData.profileData.telegramHandle);
    asForData.append('postProfileData.discordServer', collectionData.profileData.discordServer);
    asForData.append('postProfileData.twitterHandle', collectionData.profileData.twitterHandle);
    
    if (collectionData.profileData.profileGuid)
      asForData.append('postProfileData.profileGuid', collectionData.profileData.profileGuid!);

    asForData.append('name', collectionData.name);
    asForData.append('description', collectionData.description);

    if (collectionData.issuerSecurityIdentityGuid)
      asForData.append('issuerSecurityIdentityGuid', collectionData.issuerSecurityIdentityGuid);


    if (collectionData.issuerProviderGuid)
      asForData.append('issuerProviderGuid', collectionData.issuerProviderGuid);

    if (collectionData.assetCollectionGuid)
      asForData.append('assetCollectionGuid', collectionData.assetCollectionGuid);

    if (collectionData.assetCategoryGuid)
      asForData.append('assetCategoryGuid', collectionData.assetCategoryGuid);

    asForData.append('visibleToPublic', collectionData.visibleToPublic.toString());

    return this.httpClient.post<ServiceResult<boolean>>(`${environment.apiUrl}assets/CreateOrUpdateAssetCollectionWithProfile`, asForData)
      .pipe(mapServiceResult<boolean>);

  }
  CreateOrUpdateAssetSeriesWithProfile(collectionData: ICreateOrUpdateAssetSeriesRequest) {

    let asForData: FormData = new FormData();

    if (collectionData.profileData.bannerImage != null){
      asForData.append('postProfileData.bannerImage', collectionData.profileData.bannerImage, collectionData.profileData.bannerImage?.name);
    }

    if (collectionData.profileData.profilePicture != null){
      asForData.append('postProfileData.profilePicture', collectionData.profileData.profilePicture, collectionData.profileData.profilePicture?.name);
    }

    asForData.append('postProfileData.profilePictureUri', collectionData.profileData.profilePictureUri);
    asForData.append('postProfileData.bannerImageUri', collectionData.profileData.bannerImageUri);
    
    asForData.append('postProfileData.displayName', collectionData.profileData.displayName);
    asForData.append('postProfileData.description', collectionData.profileData.description);
    asForData.append('postProfileData.telegramHandle', collectionData.profileData.telegramHandle);
    asForData.append('postProfileData.discordServer', collectionData.profileData.discordServer);
    asForData.append('postProfileData.twitterHandle', collectionData.profileData.twitterHandle);

    asForData.append('metaData', collectionData.metaData)

    if (collectionData.profileData.profileGuid)
      asForData.append('postProfileData.profileGuid', collectionData.profileData.profileGuid!);

    asForData.append('assetCollectionGuid', collectionData.assetCollectionGuid);
    asForData.append('name', collectionData.name);
    asForData.append('description', collectionData.description);

    asForData.append('issuerProviderGuid', collectionData.issuerProviderGuid);

    if (collectionData.issuerSecurityIdentityGuid)
      asForData.append('issuerSecurityIdentityGuid', collectionData.issuerSecurityIdentityGuid);

    if (collectionData.assetSeriesGuid)
      asForData.append('assetSeriesGuid', collectionData.assetSeriesGuid);

    if (collectionData.assetCategoryGuid)
      asForData.append('assetCategoryGuid', collectionData.assetCategoryGuid);

    asForData.append('visibleToPublic', collectionData.visibleToPublic.toString());

    asForData.append('assetAttributeTypes', JSON.stringify(collectionData.assetAttributeTypes))

    return this.httpClient.post<ServiceResult<ICreateOrUpdateAssetSeriesResponse>>(`${environment.apiUrl}assets/CreateOrUpdateAssetSeriesWithProfile`, asForData)
      .pipe(mapServiceResult<ICreateOrUpdateAssetSeriesResponse>);

  }
  CreateOrUpdateAsset(assetData: ICreateOrUpdateAssetRequest) {

    let asForData: FormData = new FormData();

    if (assetData.assetGuid)
      asForData.append('assetGuid', assetData.assetGuid);

    asForData.append('assetTypeGuid', assetData.assetTypeGuid);

    asForData.append('assetSeriesGuid', assetData.assetSeriesGuid);
    asForData.append('name', assetData.name);
    asForData.append('metaData', assetData.metaData);
    asForData.append('attributeGuids', JSON.stringify(assetData.assetAttributeGuids));
    asForData.append('assetResources', JSON.stringify(assetData.resources));

    assetData.resources?.forEach(e => {

      if (!e.resource) {
        return;
      }

      asForData.append(`${e.assetResourceFileName}`, e.resource);

    });

    return this.httpClient.post<ServiceResult<ICreateOrUpdateAssetResponse>>(`${environment.apiUrl}assets/CreateOrUpdateAsset`, asForData)
      .pipe(mapServiceResult<ICreateOrUpdateAssetResponse>);

  }
  getAssetCollectionsForAccount() {
    return this.httpClient.get<ServiceResult<IAssetCollection[]>>(`${environment.apiUrl}assets/GetAssetCollectionsForAccount`);
  }

  private calculateTotalVaultAmount(assets: IAsset[]) {
    let vaultAmount = 0;
    assets.filter(x => x.assetSeries.assetCollection?.assetCollectionGuid === environment.vaultAssetCollectionGuid).forEach(x => {

      if (x.assetTypeGuid.toLocaleLowerCase() == environment.tokenStakeAssetTypeGuid && x.assetStatusGuid == environment.activeAssetStatusGuid){
        vaultAmount += (x.assetMetadata as IVaultAssetMetadata).amount;
      }

    });
    return vaultAmount;
  }

  getOwnedCollectionsAndAttributes(providerGuid: string) {
    return this.httpClient
      .get<ServiceResult<IOwnedCollection[]>>(
        `${environment.apiUrl}account/GetOwnedCollectionsAndAttributes`
      )
      .pipe(mapServiceResult<IOwnedCollection[]>);
  }
}


