import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss'],
})
export class ProfileCardComponent {
  @Input()
  profileGuid?: string;

  @Input()
  displayName?: string;

  @Input()
  profilePicture?: string;

  @Input()
  bannerImage?: string;

  @Input()
  isFavorite?: boolean;

  constructor() {}
}
