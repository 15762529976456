import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UiService } from '../../services/ui.service';
import { NotificationService } from '../../services/notification.service';
import { SecurityService } from '../../services/security.service';
import { NotificationType } from '../../services/notifications/notification-type';
import { ServiceResultStatus } from '../../services/results/service-result-status';
import { AccountService } from '../../services/account.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit, AfterViewInit {
  @ViewChild('modal') modalRef?: ElementRef;

  signInQrCode: string | undefined;
  signInQrUrl: SafeResourceUrl | undefined;
  showDetails: boolean = false;
  waitingMessage: string | undefined;
  signInModal: any;
  loginGroup: FormGroup;
  registerGroup: FormGroup;
  forgotPasswordGroup: FormGroup;

  formError: boolean = false;
  formSubmitted: boolean = false;
  formErrorMessage: string | undefined;
  forgotPasswordSuccess: boolean = false;

  slideIndex = 2;
  redirect: boolean = true;

  constructor(
    private notificationService: NotificationService,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private window: Window,
    private router: Router,
    private securityService: SecurityService,
    private uiService: UiService,
    private accountService: AccountService
  ) {
      this.loginGroup = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required]
      });

      this.registerGroup = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        username: ['', Validators.required],
        password: ['', Validators.required],
        terms: [false, Validators.requiredTrue]
      });

      this.forgotPasswordGroup = this.fb.group({
        email: ['', [Validators.required, Validators.email]]
      });
    }

  ngOnInit(): void {
    this.uiService.signInModal.subscribe(({ open, shouldRedirect }) => {
      this.redirect = shouldRedirect;

      if (open) {
        this.showLogin();
      } else {
        this.clearSignIn();
      }
    })

    let subscribe = <NotificationMessage>(notificationType: NotificationType, handler: (message: any | undefined) => void) => {
      this.notificationService.listenFor<NotificationMessage>(notificationType).subscribe(handler.bind(this));
    };

    subscribe(NotificationType.SignInInitializing, () => {
      this.waitingMessage = 'CREATING QR CODE';
    });

    subscribe(NotificationType.SignInInitialized, signInInstruction => {
      this.signInQrCode = signInInstruction.qrCodeImage;
      this.signInQrUrl = this.sanitizer.bypassSecurityTrustResourceUrl(signInInstruction.qrCodeUrl);
      this.waitingMessage = undefined;
    });

    subscribe(NotificationType.SignInFailed, () => {
      this.signInQrCode = undefined;
      this.signInQrUrl = undefined;
      this.waitingMessage = 'Sign-in failed: Please try again';
    });

    subscribe(NotificationType.SignInRejected, () => {
      this.signInQrCode = undefined;
      this.signInQrUrl = undefined;
      this.waitingMessage = 'Sign-in rejected';
      window.setTimeout(() => { this.clearSignIn(); }, 2000);
    });

    subscribe(NotificationType.SignInConfirmed, signInResponse => {
        this.successfulLogin();
    });

    subscribe(NotificationType.SignInApproved, signInResponse => {
      this.successfulLogin();
    });

    subscribe(NotificationType.SignInCancelled, this.clearSignIn);
  }

  ngAfterViewInit() {
    this.signInModal = new this.window.bootstrap.Modal(this.modalRef?.nativeElement, {
      backdrop: 'static',
      keyboard: false
    });
  }

  switchSlide(index: number) {
    this.loginGroup.reset();
    this.registerGroup.reset();
    this.forgotPasswordGroup.reset();
    this.formError = false;
    this.formSubmitted = false;
    this.formErrorMessage = undefined;
    this.forgotPasswordSuccess = false;

    if (index === 0) {
      this.securityService.signIn(environment.xummProviderGuid, environment.xrplBlockchainProviderGuid);
    }

    this.slideIndex = index;
  }

  cancelSignIn() {
    this.notificationService.send(NotificationType.SignInCancelled, undefined);
  }

  clearSignIn() {
    this.switchSlide(2);
    this.signInModal?.hide();
    this.showDetails = false;
    this.signInQrCode = undefined;
    this.signInQrUrl = undefined;
    this.waitingMessage = undefined;
  }

  login() {
    this.formSubmitted = true;
    this.formError = false;

    if (!this.loginGroup.valid) {
      return;
    }

    this.waitingMessage = 'Logging in';
    this.accountService.login({
      clientGuid: this.notificationService.getClientGuid(),
      providerGuid: environment.xUserProviderGuid,
      signInInfo: {
        emailAddress: this.loginGroup.get('email')?.value,
        password: this.loginGroup.get('password')?.value
      }
    }).subscribe(response => {
      if (response.status !== ServiceResultStatus.Success) {
        this.formError = true;
        this.waitingMessage = undefined;
      } else {
        this.notificationService.send(NotificationType.SignInConfirmed, { token: response.data });
      }
    });
  }

  registerSuccess(token: string) {
    this.notificationService.send(NotificationType.SignInConfirmed, { token });
  }

  forgotPassword() {
    this.formSubmitted = true;
    this.formErrorMessage = '';

    if (!this.forgotPasswordGroup.valid) {
      return;
    }

    this.waitingMessage = 'Sending email';
    this.accountService.forgotPassword({
      emailAddress: this.forgotPasswordGroup.get('email')?.value,
    }).subscribe(response => {
      if (response.status !== ServiceResultStatus.Success) {
        this.formError = true;
        this.formErrorMessage = response.message;
      } else {
        this.forgotPasswordSuccess = true;
      }

      this.waitingMessage = undefined;
    });
  }

  showLogin() {
    this.switchSlide(2);
    this.signInModal.show();
  }

  successfulLogin() {
    this.clearSignIn();

    if (this.redirect) {
      this.router.navigate(['/account/details']);
    } else {
      this.redirect = true;
    }
  }
}
