<div class="card c-pointer">
  <div
    class="card__image"
    [ngStyle]="{
      'background-image': image ? 'url(' + image + ')' : 'none'
    }"
  ></div>
  <div class="card__detail">
    <span>
      {{ name }}
    </span>
    <span> xxxxxxxxx </span>
  </div>
</div>
