import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HubConnection } from "@microsoft/signalr";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { mapServiceResult, ServiceResult } from "../results/service-result";
import { AckMessageArrivedRequest } from './ack-message-arrived-request';
import { NotificationHubConnectionDetails } from "./notification-hub-connection-details";

Injectable({
    providedIn: 'root',
})
export class NotificationHubMessageTracking {
    constructor(private httpClient: HttpClient) {}

    setupConnectionId(hubConnection : HubConnection, clientGuid : string, notificationHubTypeGuid : string) : Observable<ServiceResult<void>> {

        if (hubConnection.connectionId == null)
        {
          throw "securityConnection.connectionId was empty";
        }

        return this.httpClient
          .post(`${environment.apiUrl}notificationHub/addConnectionDetails`, new NotificationHubConnectionDetails(clientGuid, hubConnection.connectionId, notificationHubTypeGuid))
          .pipe(mapServiceResult);
    }
    ackMessage(messageGuid : string) {
        return this.httpClient
          .post(`${environment.apiUrl}notificationHub/ackMessageArrived`, new AckMessageArrivedRequest(messageGuid))
          .pipe(mapServiceResult);
    }

    setKeepAlive(hubConnection: HubConnection, keepAlive: number) : number {
        this.clearKeepAlive(keepAlive);
        return window.setInterval(() => this.keepAlive(hubConnection), 1500);
    }

    clearKeepAlive(keepAlive: any) {
        if (keepAlive)
        {
            window.clearInterval(keepAlive);
        }
    }

    keepAlive(hubConnection : HubConnection) {
        hubConnection
          .send("keepAlive")
          .catch(error => {
            // Ignore, will reconnect
        });
    }
}
