import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IBlockchainWalletInstruction } from '../models/marketplace/blockchain-wallet-instruction';
import { INftAcceptOfferProgress, NftAcceptOfferProgressStatus } from '../models/marketplace/nft-accept-offer';
import { INftCancelOfferProgress, NftCancelOfferProgressStatus } from '../models/marketplace/nft-cancel-offer';
import { INftAcceptBrokeredOfferProgress, NftAcceptBrokeredOfferProgressStatus } from '../models/marketplace/nft-accept-brokered-offer';
import { INftCollectionResult } from '../models/xls-20-nfts/nft-collection-result';
import { IFindCollectionNftsRequest } from '../models/xls-20-nfts/find-collection-nfts-request';
import { IBlockchainNftCollection } from '../models/marketplace/nft-collection';
import { NotificationService } from './notification.service';
import { ServiceResult, mapServiceResult } from './results/service-result';
import { ICurrencyAmount, IMarketplaceListing, INftCreateOfferProgress, IPurchaseInstruction, IPurchaseProgress, NftCreateOfferProgressStatus, PurchaseProgressStatus } from '../models/marketplace';
import { IAsset } from '../models/assets/asset';
import { IAssetSeries } from '../models/assets/asset-series';
import { IAssetName } from '../models/assets/asset-name';
import { NotificationResult } from './notifications/notification-result';
import { ServiceResultStatus } from './results/service-result-status';
import { NotificationType } from './notifications/notification-type';
import { IPurchaseNotification } from './notifications/messages/purchase-notification';
import { INftAcceptOfferNotification } from './notifications/messages/nft-accept-offer';
import { INftCreateOfferNotification } from './notifications/messages/nft-create-offer';
import { IBlockchainNftDetails } from '../models/block-chain-nfts/IBlockchainNftDetails';
import { IBlockchainNftCollectionSummary } from '../models/IBlockchainNftCollectionSummary';
import { IPagedBlockchainNftCollectionSummary } from '../models/IPagedBlockchainNftCollectionSummary';
import { IBlockchainNftCollectionRequest } from '../models/IBlockchainNftCollectionRequest';
import { IBlockchainNftOfferDetails } from '../models/block-chain-nfts/IBlockchainNftOfferDetails';
import { IApplyLaunchpad } from '../models/IApplyLaunchpad';
import { IAssetCollection } from '../models/assets/asset-collection';
import { IAssetCategorySummary } from '../models/IAssetCategorySummary';
import { IAssetAttributeType } from '../models/assets/asset-attribute-type';
import { IAssetSeriesForEdit } from '../models/assets/asset-series-for-edit';
import { IAssetForEdit } from '../models/assets/asset-for-edit';
import { IFindPagedAssetsInSeriesRequest } from '../models/assets/IFindPagedAssetsInSeriesRequest';
import { IFindPagedAssetsInSeriesResponse } from '../models/assets/FindPagedAssetsInSeriesResponse';
import { ICurrencyType } from '../models/ICurrencyType';

@Injectable({
  providedIn: 'root',
 })
 export class MarketplaceService {

  constructor(
    private notificationService: NotificationService,
    private httpClient: HttpClient) { }

  getListings() {
    return this.httpClient.get<ServiceResult<IMarketplaceListing[]>>(`${environment.apiUrl}listings`);
  }

  getAssetsAttributes(assetSeriesGuid: string) {
    return this.httpClient.get<ServiceResult<IAssetAttributeType[]>>(`${environment.apiUrl}assets/series/${assetSeriesGuid}/Attributes`);
  }

  getAllAssetSeries() {
    return this.httpClient.get<ServiceResult<IAssetSeries[]>>(`${environment.apiUrl}assets/series/all`);
  }

  getAssetCollection(assetCollectionGuid: string) {
    return this.httpClient.get<ServiceResult<IAssetCollection>>(`${environment.apiUrl}assets/collection/${assetCollectionGuid}`);
  }

  getAssetSeries(asserSeriesGuid: string)  {
    return this.httpClient.get<ServiceResult<IAssetSeries>>(`${environment.apiUrl}assets/series/${asserSeriesGuid}`)
      .pipe(mapServiceResult<IAssetSeries>)
  }

  findPagedAssetsForSeries(request: IFindPagedAssetsInSeriesRequest)  {
    return this.httpClient.post<ServiceResult<IFindPagedAssetsInSeriesResponse>>(`${environment.apiUrl}assets/findPagedAssetsForSeries`, request)
      .pipe(mapServiceResult<IFindPagedAssetsInSeriesResponse>)
  }

  getAssetSeriesForEditing(asserSeriesGuid: string)  {
    return this.httpClient.get<ServiceResult<IAssetSeriesForEdit>>(`${environment.apiUrl}assets/series/${asserSeriesGuid}/edit`);
  }

  getAssetForEditing(asserGuid: string)  {
    return this.httpClient.get<ServiceResult<IAssetForEdit>>(`${environment.apiUrl}assets/${asserGuid}/edit`);
  }

  getAssetSeriesAttributes(assetSeriesGuid: string) {
    return this.httpClient.get<ServiceResult<IAssetAttributeType[]>>(`${environment.apiUrl}assets/series/${assetSeriesGuid}/Attributes`);
  }

  getTenantCurrencies() {
    return this.httpClient.get<ServiceResult<ICurrencyType[]>>(`${environment.apiUrl}/TenantCurrencies`);
  }


  getAllAssetSeriesInAssetCollection(assetCollectionGuid: string) {
    return this.httpClient.get<ServiceResult<IAssetSeries[]>>(`${environment.apiUrl}assets/collection/${assetCollectionGuid}/series`);
  }

  getAllAssetsByAssetSeries(assetSeriesGuid: string) {
    return this.httpClient.get<ServiceResult<IAsset[]>>(`${environment.apiUrl}assets/allAssetsByAssetSeries&AssetSeriesGuid=${assetSeriesGuid}`);
  }

  getAllAssetGuidsByAssetSeries(assetSeriesGuid: string) {
    return this.httpClient.get<ServiceResult<string[]>>(`${environment.apiUrl}assets/guids/allAssetsByAssetSeries&AssetSeriesGuid=${assetSeriesGuid}`);
  }

  getAllAssetNamesByAssetSeries(assetSeriesGuid: string) {
    return this.httpClient.get<ServiceResult<IAssetName[]>>(`${environment.apiUrl}assets/names/allAssetsByAssetSeries&AssetSeriesGuid=${assetSeriesGuid}`);
  }



  initializePurchase(marketplaceListing: IMarketplaceListing, quantity: number, providerGuid?: String) : Observable<NotificationResult<IPurchaseInstruction | undefined, IPurchaseProgress>> {
    if(!providerGuid) {
      providerGuid = environment.xummProviderGuid;
    }

    let marketplacePurchase = { marketplaceListingGuid: marketplaceListing.marketplaceListingGuid, providerGuid: providerGuid, quantity: quantity };

    return this.httpClient.post<ServiceResult<IPurchaseInstruction>>(`${environment.apiUrl}listings/${marketplaceListing.marketplaceListingGuid}/purchase`, marketplacePurchase)
      .pipe(mapServiceResult<IPurchaseInstruction>)
      .pipe(map(result => {
        let notificationResult = new NotificationResult<IPurchaseInstruction | undefined, IPurchaseProgress>(result.data);

        if (result.status === ServiceResultStatus.Success && result.data) {
          let subscriptions: Subscription[] = [];
          let transactionGuid = result.data.transactionGuid;
          let clearSubscriptions = () => {
            subscriptions.forEach(x => x.unsubscribe());
            subscriptions = [];
          }

          subscriptions.push(this.notificationService.listenFor<IPurchaseNotification>(NotificationType.PurchaseAwaitingConfirmation).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setNext({
                progressStatus: PurchaseProgressStatus.AwaitingConfirmation,
                purchasedAssetGuids: undefined,
                progressMessage: notification.message
              });
            }
          }));

          subscriptions.push(this.notificationService.listenFor<IPurchaseNotification>(NotificationType.PurchaseProcessing).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setNext({
                progressStatus: PurchaseProgressStatus.Processing,
                purchasedAssetGuids: undefined,
                progressMessage: notification.message
              });
            }
          }));

          subscriptions.push(this.notificationService.listenFor<IPurchaseNotification>(NotificationType.PurchaseFailed).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setResult({
                progressStatus: PurchaseProgressStatus.Failed,
                purchasedAssetGuids: undefined,
                progressMessage: notification.message
              });
              clearSubscriptions();
            }
          }));

          subscriptions.push(this.notificationService.listenFor<IPurchaseNotification>(NotificationType.PaymentFailed).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setResult({
                progressStatus: PurchaseProgressStatus.Failed,
                purchasedAssetGuids: undefined,
                progressMessage: notification.message
              });
              clearSubscriptions();
            }
          }));

          subscriptions.push(this.notificationService.listenFor<IPurchaseNotification>(NotificationType.PurchaseCompleted).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setResult({
                progressStatus: PurchaseProgressStatus.Complete,
                purchasedAssetGuids: notification.purchasedAssetGuids,
                progressMessage: notification.message
              });
              clearSubscriptions();
            }
          }));
        }

        return notificationResult
      }));
  }

  claimNft(asset: IAsset) : Observable<NotificationResult<IBlockchainWalletInstruction | undefined, INftAcceptOfferProgress>> {

    let claimNft = { assetGuid: asset.assetGuid, providerGuid: environment.xummProviderGuid };

    return this.httpClient.post<ServiceResult<IBlockchainWalletInstruction>>(`${environment.apiUrl}assets/${asset.assetGuid}/claimNft`, claimNft)
      .pipe(mapServiceResult<IBlockchainWalletInstruction>)
      .pipe(map(result => {
        let notificationResult = new NotificationResult<IBlockchainWalletInstruction | undefined, INftAcceptOfferProgress>(result.data);

        if (result.status === ServiceResultStatus.Success && result.data) {
          let subscriptions: Subscription[] = [];
          let transactionGuid = result.data.transactionGuid;
          let clearSubscriptions = () => {
            subscriptions.forEach(x => x.unsubscribe());
            subscriptions = [];
          }

          subscriptions.push(this.notificationService.listenFor<INftAcceptOfferNotification>(NotificationType.AcceptOfferAwaitingConfirmation).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setNext({
                progressStatus: NftAcceptOfferProgressStatus.AwaitingConfirmation,
                transactionGuid: transactionGuid
              });
            }
          }));

          subscriptions.push(this.notificationService.listenFor<INftAcceptOfferNotification>(NotificationType.AcceptOfferFailed).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setResult({
                progressStatus: NftAcceptOfferProgressStatus.Failed,
                transactionGuid: transactionGuid
              });
              clearSubscriptions();
            }
          }));

          subscriptions.push(this.notificationService.listenFor<INftAcceptOfferNotification>(NotificationType.AcceptOfferComplete).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setResult({
                progressStatus: NftAcceptOfferProgressStatus.Complete,
                transactionGuid: transactionGuid
              });
              clearSubscriptions();
            }
          }));
        }

        return notificationResult
      }));

  }

  acceptXls20NftOffer(offer: IBlockchainNftOfferDetails, nftTokenId : string, nftName : string, accountGuid: string, paymentInstrumentGuid : string) : Observable<NotificationResult<IBlockchainWalletInstruction | undefined, INftAcceptOfferProgress>> {

    let acceptOffer = {
      paymentInstrumentGuid : paymentInstrumentGuid,
      accountGuid : accountGuid,
      nftName : nftName,
      nftokenId : nftTokenId,
      offerId : offer.blockchainOfferLedgerId
    };

    return this.httpClient.post<ServiceResult<IBlockchainWalletInstruction>>(`${environment.apiUrl}xls-20/acceptOfferOnNft`, acceptOffer)
      .pipe(mapServiceResult<IBlockchainWalletInstruction>)
      .pipe(map(result => {
        let notificationResult = new NotificationResult<IBlockchainWalletInstruction | undefined, INftAcceptOfferProgress>(result.data);

        if (result.status === ServiceResultStatus.Success && result.data) {
          let subscriptions: Subscription[] = [];
          let transactionGuid = result.data.transactionGuid;
          let clearSubscriptions = () => {
            subscriptions.forEach(x => x.unsubscribe());
            subscriptions = [];
          }

          subscriptions.push(this.notificationService.listenFor<INftAcceptOfferNotification>(NotificationType.AcceptOfferAwaitingConfirmation).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setNext({
                progressStatus: NftAcceptOfferProgressStatus.AwaitingConfirmation,
                transactionGuid: transactionGuid
              });
            }
          }));

          subscriptions.push(this.notificationService.listenFor<INftAcceptOfferNotification>(NotificationType.AcceptOfferFailed).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setResult({
                progressStatus: NftAcceptOfferProgressStatus.Failed,
                transactionGuid: transactionGuid
              });
              clearSubscriptions();
            }
          }));

          subscriptions.push(this.notificationService.listenFor<INftAcceptOfferNotification>(NotificationType.AcceptOfferComplete).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setResult({
                progressStatus: NftAcceptOfferProgressStatus.Complete,
                transactionGuid: transactionGuid
              });
              clearSubscriptions();
            }
          }));
        }

        return notificationResult
      }));

  }

  acceptBrokeredXls20NftOffer(offer: IBlockchainNftOfferDetails, nftTokenId : string, nftName : string, accountGuid: string, paymentInstrumentGuid : string, currentOwnerAddress: string) : Observable<NotificationResult<IBlockchainWalletInstruction | undefined, INftAcceptBrokeredOfferProgress>> {

    let acceptOffer = {
      paymentInstrumentGuid : paymentInstrumentGuid,
      accountGuid : accountGuid,
      nftName : nftName,
      nftokenId : nftTokenId,
      offerId : offer.blockchainOfferLedgerId,
      wasSellOffer: offer.isSellOffer,
      currentOwnerAddress: currentOwnerAddress
    };

    return this.httpClient.post<ServiceResult<IBlockchainWalletInstruction>>(`${environment.apiUrl}xls-20/acceptBrokeredOfferOnNft`, acceptOffer)
      .pipe(mapServiceResult<IBlockchainWalletInstruction>)
      .pipe(map(result => {
        let notificationResult = new NotificationResult<IBlockchainWalletInstruction | undefined, INftAcceptBrokeredOfferProgress>(result.data);

        if (result.status === ServiceResultStatus.Success && result.data) {
          let subscriptions: Subscription[] = [];
          let transactionGuid = result.data.transactionGuid;
          let clearSubscriptions = () => {
            subscriptions.forEach(x => x.unsubscribe());
            subscriptions = [];
          }

          subscriptions.push(this.notificationService.listenFor<INftAcceptOfferNotification>(NotificationType.AcceptBrokeredOfferAwaitingConfirmation).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setNext({
                progressStatus: NftAcceptBrokeredOfferProgressStatus.AwaitingConfirmation,
                transactionGuid: transactionGuid
              });
            }
          }));

          subscriptions.push(this.notificationService.listenFor<INftAcceptOfferNotification>(NotificationType.AcceptBrokeredOfferFailed).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setResult({
                progressStatus: NftAcceptBrokeredOfferProgressStatus.Failed,
                transactionGuid: transactionGuid
              });
              clearSubscriptions();
            }
          }));

          subscriptions.push(this.notificationService.listenFor<INftAcceptOfferNotification>(NotificationType.AcceptBrokeredOfferComplete).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setResult({
                progressStatus: NftAcceptBrokeredOfferProgressStatus.Complete,
                transactionGuid: transactionGuid
              });
              clearSubscriptions();
            }
          }));
        }

        return notificationResult
      }));

  }

  cancelXls20NftOffer(offerId : string, nftName : string, accountGuid: string, paymentInstrumentGuid : string) : Observable<NotificationResult<IBlockchainWalletInstruction | undefined, INftCancelOfferProgress>> {

    let cancelOfferOnNftInitialization = {
      paymentInstrumentGuid : paymentInstrumentGuid,
      accountGuid : accountGuid,
      nftName : nftName,
      offerId : offerId
    };

    return this.httpClient.post<ServiceResult<IBlockchainWalletInstruction>>(`${environment.apiUrl}xls-20/cancelOfferOnNft`, cancelOfferOnNftInitialization)
      .pipe(mapServiceResult<IBlockchainWalletInstruction>)
      .pipe(map(result => {
        let notificationResult = new NotificationResult<IBlockchainWalletInstruction | undefined, INftCancelOfferProgress>(result.data);

        if (result.status === ServiceResultStatus.Success && result.data) {
          let subscriptions: Subscription[] = [];
          let transactionGuid = result.data.transactionGuid;
          let clearSubscriptions = () => {
            subscriptions.forEach(x => x.unsubscribe());
            subscriptions = [];
          }

          subscriptions.push(this.notificationService.listenFor<INftCreateOfferNotification>(NotificationType.CancelOfferFailed).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setResult({
                progressStatus: NftCancelOfferProgressStatus.Failed,
                transactionGuid: transactionGuid
              });
              clearSubscriptions();
            }
          }));

          subscriptions.push(this.notificationService.listenFor<INftCreateOfferNotification>(NotificationType.CancelOfferComplete).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setResult({
                progressStatus: NftCancelOfferProgressStatus.Complete,
                transactionGuid: transactionGuid
              });
              clearSubscriptions();
            }
          }));
        }

        return notificationResult
      }));

  }

  makeXls20NftOffer(ownerAddress : string, amount: string, nftTokenId : string, nftName : string, accountGuid: string, paymentInstrumentGuid : string, currencyId : string, isSellOffer : boolean, destinationWalletAddress: string  = '') : Observable<NotificationResult<IBlockchainWalletInstruction | undefined, INftCreateOfferProgress>> {

    let makeOfferOnNftRequest = {
      paymentInstrumentGuid : paymentInstrumentGuid,
      accountGuid : accountGuid,
      nftName : nftName,
      nftokenId : nftTokenId,
      amount : amount,
      isSellOffer : isSellOffer,
      ownerWalletAddress : ownerAddress,
      currencyId: currencyId,
      destinationWalletAddress: destinationWalletAddress
    };

    return this.httpClient.post<ServiceResult<IBlockchainWalletInstruction>>(`${environment.apiUrl}xls-20/makeOfferOnNft`, makeOfferOnNftRequest)
      .pipe(mapServiceResult<IBlockchainWalletInstruction>)
      .pipe(map(result => {
        let notificationResult = new NotificationResult<IBlockchainWalletInstruction | undefined, INftCreateOfferProgress>(result.data);

        if (result.status === ServiceResultStatus.Success && result.data) {
          let subscriptions: Subscription[] = [];
          let transactionGuid = result.data.transactionGuid;
          let clearSubscriptions = () => {
            subscriptions.forEach(x => x.unsubscribe());
            subscriptions = [];
          }

          subscriptions.push(this.notificationService.listenFor<INftCreateOfferNotification>(NotificationType.CreateOfferFailed).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setResult({
                progressStatus: NftCreateOfferProgressStatus.Failed,
                transactionGuid: transactionGuid
              });
              clearSubscriptions();
            }
          }));

          subscriptions.push(this.notificationService.listenFor<INftCreateOfferNotification>(NotificationType.CreateOfferComplete).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setResult({
                progressStatus: NftCreateOfferProgressStatus.Complete,
                transactionGuid: transactionGuid
              });
              clearSubscriptions();
            }
          }));
        }

        return notificationResult
      }));

  }

  mintXls20Nft(assetGuid : string, blockchainProviderGuid : string) : Observable<NotificationResult<IBlockchainWalletInstruction | undefined, INftCancelOfferProgress>> {

    let mintNftInitialization = {
      assetGuid : assetGuid,
      blockchainProviderGuid : blockchainProviderGuid
    };

    return this.httpClient.post<ServiceResult<IBlockchainWalletInstruction>>(`${environment.apiUrl}assets/MintNft`, mintNftInitialization)
      .pipe(mapServiceResult<IBlockchainWalletInstruction>)
      .pipe(map(result => {
        let notificationResult = new NotificationResult<IBlockchainWalletInstruction | undefined, INftCancelOfferProgress>(result.data);

        if (result.status === ServiceResultStatus.Success && result.data) {
          let subscriptions: Subscription[] = [];
          let transactionGuid = result.data.transactionGuid;
          let clearSubscriptions = () => {
            subscriptions.forEach(x => x.unsubscribe());
            subscriptions = [];
          }

          subscriptions.push(this.notificationService.listenFor<INftCreateOfferNotification>(NotificationType.MintFailed).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setResult({
                progressStatus: NftCancelOfferProgressStatus.Failed,
                transactionGuid: transactionGuid
              });
              clearSubscriptions();
            }
          }));

          subscriptions.push(this.notificationService.listenFor<INftCreateOfferNotification>(NotificationType.MintSuccessful).subscribe(notification => {
            if (notification.transactionGuid === transactionGuid) {
              notificationResult.setResult({
                progressStatus: NftCancelOfferProgressStatus.Complete,
                transactionGuid: transactionGuid
              });
              clearSubscriptions();
            }
          }));
        }

        return notificationResult
      }));

  }

  findXls20NftsByCollectionName(findParams: IFindCollectionNftsRequest) {
    return this.httpClient.post<ServiceResult<INftCollectionResult>>(`${environment.apiUrl}xls-20/${findParams.collectionName}/findCollectionNfts`,
    findParams,
    );
  }

  findXls20Nfts(findParams: IFindCollectionNftsRequest) {
    return this.httpClient.post<ServiceResult<INftCollectionResult>>(`${environment.apiUrl}xls-20/findNfts`,
    findParams,
    );
  }

  getBlockchainNftCollections() {
    return this.httpClient.get<ServiceResult<IBlockchainNftCollection[]>>(`${environment.apiUrl}xls-20/GetAllNftCollections`);
  }

  getNftDetailsByNftToken(nftTokenId: string) {
    return this.httpClient.get<ServiceResult<IBlockchainNftDetails>>(`${environment.apiUrl}xls-20/${nftTokenId}/GetNftDetailsByNftToken`);
  }

  getNftsByCollectionName(collectionName: string) {
    return this.httpClient.get<ServiceResult<IBlockchainNftDetails[]>>(`${environment.apiUrl}xls-20/GetNftsByCollectionName/${collectionName}`);
  }

  getAllBlockchainNftCollectionsSearch(collectionRequest:IBlockchainNftCollectionRequest) {
    if(!collectionRequest.skip){
      collectionRequest.skip = 0;
    }
    if(!collectionRequest.take){
      collectionRequest.take = 10;
    }
    return this.httpClient.post<ServiceResult<IPagedBlockchainNftCollectionSummary>>(`${environment.apiUrl}xls-20/GetAllNftCollections`, collectionRequest);
  }

  getNftBlockChainCollectionByGuid(collectionGuid:string): Observable<ServiceResult<IBlockchainNftCollectionSummary>>{
    return this.httpClient.get<ServiceResult<IBlockchainNftCollectionSummary>>(`${environment.apiUrl}xls-20/GetNftBlockChainCollectionByGuid/${collectionGuid}`);
  }

  getNftBlockChainCollectionByName(collectionName:string): Observable<ServiceResult<IBlockchainNftCollectionSummary>>{
    return this.httpClient.get<ServiceResult<IBlockchainNftCollectionSummary>>(`${environment.apiUrl}xls-20/GetNftBlockChainCollectionByName/${collectionName}`);
  }

  getTenantCategories():Observable<ServiceResult<IAssetCategorySummary[]>>{
    return this.httpClient.get<ServiceResult<IAssetCategorySummary[]>>(`${environment.apiUrl}TenantCategories`);
  }

  submitToLaunchpad(applyLaunchpad:IApplyLaunchpad){
    return this.httpClient.post<ServiceResult<boolean>>(`${environment.apiUrl}SubmitToLaunchpad`, applyLaunchpad);
  }

  
  createAsset(data:FormData): Observable<ServiceResult<any>>{
    return this.httpClient.post<ServiceResult<any>>(`${environment.apiUrl}assets/CreateAsset`,data);
  }
}
