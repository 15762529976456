<div class="overlay" [ngClass]="{ 'active': navigationOpen }"></div>

<section class="side-menu" [ngClass]="{ 'active': navigationOpen }">
  <div class="side-menu__top">
    <img src="../../../assets/images/logo.svg" alt="xSpectar" />
    <app-icon [icon]="'fa-times'" (click)="closeNavbar()"></app-icon>
  </div>

  <div class="side-menu__main">
    <nav class="side-menu__links">
      <div>

        <ul *ngIf="showLogin">
          <li>
            <span class="navigation-menu-link" routerLink="/explore">Login</span>
          </li>
        </ul>

        
        <div *ngFor="let collection of menuItemCollections">
          <h2>{{collection.label}}</h2>
          <ul>
            <li *ngFor="let menuItem of collection.items">
              <a class="navigation-menu-link" (click)="selectMenuItem(menuItem)" >{{menuItem.label}}</a>
            </li>
          </ul>
        </div>
        

      </div>
    </nav>

  </div>
</section>
